@import  "../../utilities/globals"
.register
    text-align: center
    // display: flex
    // justify-content: center
    // align-items: center
    display: table
    width: 100%
    height: 100%
    .text-error
        color: red
        display: block
    .title
        cursor: pointer
        color: $primary
        p
            margin: 0
    .register-content
        width: 100%
        display: table-cell
        vertical-align: middle
        .register-body
            // max-width: 500px
            margin: auto
            .title, .title-or
                cursor: default
                color: $tertiary
            .title-or
                font-weight: bold
                margin-top: 1rem!important
            .form-register
                // margin: 1rem 0 0 0!important
                input
                    border-color: $primary!important
                ::-webkit-input-placeholder
                    text-align: center
                :-moz-placeholder // Firefox 18- */
                    text-align: center
                ::-moz-placeholder  // Firefox 19+ */
                    text-align: center  
                :-ms-input-placeholder
                    text-align: center 
            button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
                background: $primary
                color: white
                border-radius: 20px
                margin-top: 1rem
                &:hover
                    color: $primary
                    background: white
                    border: 1px solid $primary
            p.p-forgot
                color: $primary
                text-decoration: underline
                text-decoration-color: $primary
                cursor: pointer
                margin: 1rem 0
            .register-now
                color: $tertiary
                padding-bottom: 1rem
                span
                    color: $secondary!important
                    text-decoration: underline
                    text-decoration-color: $secondary
//tablet horizontal
@media only screen and (min-width: 991px)
    div.register
        // display: flex!important
//tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    .register
        // display: flex
// mobile vertical
@media only screen and (max-width: 768px)
    .register
        .register-content
            .register-body
            min-width: inherit
            width: 100%
            .form-register
                input
                    // border-radius: 20px!important
            button.btn-continue
                width: 85%
            .footer
                font-size: 11px

@media screen and (max-width: 327px)
    div.register
        // display: block
// small mobile vertical
@media only screen and (min-width: 327px) and (orientation : landscape)
    div.register
        // display: block