.listItemWrapper
  padding: 1rem
  border-radius: 9px
  border: none
  background-color: #fff
  transition: all 0.3s

  &:hover
    transform: scale(1.02)
    cursor: pointer
