@import "../../utilities/globals"
@import "../../../sources/mixins"

.googleAutocompleteWrapper
    position: relative
    margin-bottom: 1rem
    min-height: 40px
    border: 1px solid #d7d7d7
    border-radius: 9px
    -webkit-box-shadow: 0 0 0 2px transparent
    -moz-box-shadow: 0 0 0 2px transparent
    box-shadow: 0 0 0 2px transparent
    transition: all 0.3s

    &:not(.danger)
        @include hover()
            border-color: $primary

    &:not(.danger):focus-within
        -webkit-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
        -moz-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
        box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
        border-color: $primary

    input
        border: none !important
        border-radius: 9px !important

.ghost
    border-color: transparent !important

    input
        height: 38px

.danger
    border-color: $negative

    &:focus-within
        -webkit-box-shadow: 0 0 0 2px rgba($negative,0.3)
        -moz-box-shadow: 0 0 0 2px rgba($negative,0.3)
        box-shadow: 0 0 0 2px rgba($negative,0.3)

.errorMessage
    position: absolute
    left: 0
    top: 2.75rem
    display: inline-block
    font-size: 0.875rem
    line-height: 1
    color: $negative
    opacity: 0
    transition: all 0.3s

.errorMessageVisible
    opacity: 1

div.googleDisabled
    input
        pointer-events: none
        opacity: 1 !important
        border: none!important
        box-shadow: none!important
        padding: 0!important
        color: transparent

        &::-webkit-input-placeholder, &::-moz-placeholder, &::-ms-input-placeholder
            color: transparent
            opacity: 1
