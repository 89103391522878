@import "../../../globals"
@import "../../../../../sources/mixins"

.swiperCardWrapper
  max-width: 336px
  height: 100%
  background-color: #fff
  border: 1px solid $secondary
  border-radius: 10px
  transform: scale(1)
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s
  user-select: none
  opacity: 0

  @include breakpoint('lg')
    max-width: 260px

  @include breakpoint('md')
    max-width: 220px

  .cardContent
    padding: 16px
    color: $textColor

    @include breakpoint('lg')
      padding: 12px

    @include breakpoint('md')
      padding: 8px

    .cardHeader
      position: relative
      display: flex
      flex-direction: column
      align-items: center
      margin-bottom: 8px

      .title
        margin-bottom: 0
        font-size: 24px
        font-weight: 500
        line-height: 1
        text-transform: uppercase

        @include breakpoint('lg')
          font-size: 18px

      .subtitle
        font-size: 16px

        @include breakpoint('lg')
          font-size: 12px

      .popupIcon
        position: absolute
        top: 0
        right: 0
        stroke: $secondary

      .popupContent
        max-width: 100px

    .cardDivider
      height: 1px
      background-color: $secondary

    .marginTop
      margin-top: 16px

      @include breakpoint('md')
        margin-top: 12px

    .imageWrapper
      text-align: center

      > img
        width: 65%

        @include breakpoint('lg')
          width: 60%

    .cardList
      display: flex
      flex-direction: column
      padding: 0
      margin: 0
      list-style: none
      gap: 8px

    .bottomActions
      display: flex
      flex-direction: column
      margin-top: 12px
      gap: 12px

      @include breakpoint('md')
        gap: 8px
        margin-top: 8px

      .record
        display: flex
        justify-content: space-between
        gap: 20px
        width: 100%

        @include breakpoint('lg')
          gap: 12px

        > div
          display: flex
          align-items: center
          justify-content: space-between
          width: 100%
          gap: 20px

          @include breakpoint('lg')
            gap: 12px

        .vertical
          display: flex
          flex-direction: column
          gap: 4px

          .label, .percent
            text-align: center
            font-size: 10px
            font-weight: 400

          .percent
            color: $secondary

          .value
            text-align: center
            font-size: 12px
            font-weight: 400

          .grey
            color: #b8b8b8

        .shoppingCartWrapper
          width: fit-content

          > button
            padding: 4px
            width: fit-content
            background-color: transparent
            border: none

.decreased
  transform: scale(0.8)

.visible
  opacity: 1

.selected
  background-color: #dcffe2

.notSelected
  background-color: #ffd8d8
