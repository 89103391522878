@import '../../../sources/mixins'

.buyers
  margin: 12px 0 0 !important
  padding: 0 40px !important

  @include breakpoint('md')
    padding-inline: 28px !important

  @include breakpoint('sm')
    padding-inline: 16px !important

  .header
    margin-bottom: 1rem
    font-size: 24px

  .buyersList
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 20px
    padding: 0

    @include breakpoint('xxl')
      gap: 12px

    @include breakpoint('xl')
      grid-template-columns: repeat(2, 1fr)

    @include breakpoint('sm')
      grid-template-columns: repeat(1, 1fr)
