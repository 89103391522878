@import  "../../utilities/globals"
@import "../../../sources/mixins"

.buttonContainer
  .getPreQualifiedWrapper
    display: flex
    justify-content: center

  .ui.button
    @include breakpoint('lg')
      padding: 0.5rem 1.25rem

      h3
        font-size: 1rem

  span
    @include breakpoint('lg')
      font-size: 12px

.simple-register
  .radio-form
    .radio-input
      .ui.header
        display: inline-block
        margin: 0

      p
        margin: 0

        @include breakpoint('lg')
          font-size: 1rem

      small
        @include breakpoint('lg')
          font-size: 0.75rem

      label
        display: flex!important
        align-items: center
      input
        margin-right: 1rem
    .field
      margin: 1em 0!important
    .ui.form .fields
      display: block

  .continueButtonWrapper
    display: flex
    justify-content: center
    margin-top: 1rem

    > button
      margin: 0 !important

      h3
        @include breakpoint('md')
          font-size: 1rem

  button.continueButton
    background: $primary
    color: white
    border-radius: 20px
    border: 1px solid $primary

    &:hover
      background-color: #fff
      color: $primary

    @include breakpoint('md')
      margin-bottom: 0 !important

    @include breakpoint('sm')
      padding: 12px 20px !important

  button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
    background: $primary
    color: white
    border-radius: 20px
    margin-top: 1rem
    border: 1px solid $primary

    &:hover
      color: $primary
      background: white

  .close
    border-radius: 20px

  .title, .title-or
    cursor: default
    color: $tertiary
    text-align: center

  .title-or
    font-weight: bold
    text-align: center
    margin-top: 1rem!important

    @include breakpoint('xl')
      font-size: 1.25rem

    @include breakpoint('md')
      font-size: 0.875rem

  .upperTitle
    margin-top: 0
    cursor: pointer
    color: $primary
    text-align: center

    @include breakpoint('xl')
      font-size: 1.5rem

  .title
    margin-top: 0
    cursor: pointer
    color: $primary

    @include breakpoint('xl')
      font-size: 1.5rem

    @include breakpoint('md')
      font-size: 1rem

    p
      margin: 0
