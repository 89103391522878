@import "../globals"
@import "../../../sources/mixins"

.content-ranges
    .actionButtonsWrapper
        display: flex
        gap: 8px

        @include breakpoint('lg')
            justify-content: end

        button
            @include breakpoint('xl')
                min-width: 120px
                padding: 1rem !important
                font-size: 1rem !important

            @include breakpoint('md')
                min-width: 100px
                padding: 0.75rem !important

    div.fields
        @include breakpoint('md')
            flex-wrap: nowrap !important

            div.field
                margin-bottom: 0.5rem !important

        @include breakpoint('sm')
            flex-wrap: wrap !important

    textarea
        resize: none !important

    label
        color: $tertiary!important
        font-weight: 700!important
        width: 100%
        display: block
    .required-inut
        color: red!important
    ::-webkit-input-placeholder
        color: transparent
    :-moz-placeholder
        color: transparent
    ::-moz-placeholder
        color: transparent
    :-ms-input-placeholder
        color: transparent
    input::placeholder
        color: transparent
    .customButton
        padding: 0 !important
        margin-top: 20px

    .disabled-check
        display: flex
        align-items: center
        gap: 2rem
        opacity: 1

        label
            margin-bottom: 0

        .disabled, input, label
            opacity: 1!important
    button
        font-size: 1.2rem !important
        padding: 17.5px 35px !important
        font-weight: 500 !important
    button.acceptButton, button.acceptButton:active, button.acceptButton:focus
        background-color: $primary
        color: white
        border: 1px solid $primary
        &:hover
            background-color: white
            color: $primary
    button.editButton, button.editButton:active, button.editButton:focus
        background-color: $primary
        color: white
        border: 1px solid $primary
        &:hover
            background-color: white
            color: $primary
    .cancelButton, button.cancelButton:active, button.cancelButton:focus
        background-color: red
        color: white
        border: 1px solid red
        &:hover
            background-color: white
            color: red
    .closeButton, button.closeButton:active, button.closeButton:focus
        background-color: $primary
        color: white
        border: 1px solid $primary
        &:hover
            color: $primary
            background-color: white
