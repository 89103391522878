@import './breakpoints.sass'

@mixin breakpoint($breakpoint)
  @if map-has-key($breakpoints, $breakpoint)
    $breakpoint-value: map-get($breakpoints, $breakpoint)

    @media (max-width: $breakpoint-value)
      @content

@mixin squareShape($value)
  width: $value
  height: $value

@mixin hover()
  @media (hover: hover)
    &:hover
      @content
