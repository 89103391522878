@import "../../../sources/mixins"

.payment-plans
  width: 100%

  .header
    margin-bottom: 1.5rem

  .search-form,
  .filterForm
    div.customInputWrapper,
    div.customDropdownWrapper,
    div.dateInputWrapper
      margin-bottom: 0

  .filterForm
    @include breakpoint('md')
    .fields > .field
      margin-bottom: 1rem !important

    .dateRange
      div.column
        padding-inline: 0.25rem !important

  .plan-status
    width: 100%
    pointer-events: none
    border-radius: 2px!important

  .table-wrapper
    width: 100%
    overflow-x: auto

    .customTable
      min-width: 1000px

.plansStadistics
  display: flex
  align-items: end!important
  margin: 0 !important

  @include breakpoint('xl')
    flex-wrap: nowrap !important

  @include breakpoint('sm')
    flex-wrap: wrap !important

  .statisticCard
    width: max-content
    min-width: 150px
    text-align: center
    padding: 25px
    border-radius: 20px
    box-shadow: 1px 1px 5px #979494
    transition: 200ms all

    @include breakpoint('lg')
      width: 100%
      padding: 20px

    @include breakpoint('md')
      min-width: 100px
      padding: 12px

    &:not(:first-child)
      margin-left: 20px

      @include breakpoint('sm')
        margin-left: 0
        margin-top: 1rem

    .ui.statistic
      div.value,
      div.label
        color: #FFF!important

      @include breakpoint('lg')
        div.value
          font-size: 32px !important

        div.label
          font-size: 14px !important

      @include breakpoint('md')
        div.value
          font-size: 20px !important

        div.label
          font-size: 12px !important
