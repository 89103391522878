@import  "../globals"
@import "../../../sources/mixins"

.inventory
  display: grid

  .headerBreadcrumbs
    margin-bottom: 8px !important
    padding-left: 0 !important

  .retailer-select
    display: flex
    align-items: center
    gap: 8px

    > label
      padding: 0 !important

    div.customDropdownWrapper
      margin-bottom: 0

  .search-form
    padding: 0.5rem

    .yearInputWrapper > div.field
      margin-bottom: 0 !important

    .fields
      justify-content: space-between

    .field
      margin-bottom: 1em

  .filters-form
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 12px

    @include breakpoint('lg')
      grid-template-columns: repeat(2, 1fr)

    @include breakpoint('md')
      grid-template-columns: repeat(1, 1fr)

    div.five.wide.field
      width: 100% !important
      margin-bottom: 0 !important

    .ui.grid
      flex-wrap: nowrap
      gap: 12px

      @include breakpoint('xs')
        flex-wrap: wrap
        gap: 8px

    .datePickerContainer
      .field
        @include breakpoint('md')
          margin-bottom: 0 !important

    .datePicker
      .ui.icon.input>input
        padding-right: 2rem !important

      input
        padding: 8px 12px !important
        height: 38px !important

      @include breakpoint('xs')
        div.field
          margin-bottom: 0 !important

  .top-form
    display: flex
    justify-content: space-between

    @include breakpoint('xs')
      flex-direction: column

    .button
      @include breakpoint('md')
        width: 100%
        padding: 12px 16px
        font-size: 1rem

    .retailer-select .field
      display: flex
      align-items: center

      @include breakpoint('xs')
        flex-direction: column
        align-items: start

    .field
      margin-bottom: 0

      label
        padding-right: 12px

        @include breakpoint('xs')
          padding-right: 0
          padding-bottom: 8px

      .ui.selection.dropdown
        @include breakpoint('md')
          min-width: 8em
          width: 8em

        @include breakpoint('xs')
          width: 100%

  .inventory-table
    height: 45vh
    overflow-y: auto
    padding: 0 0rem 0.5rem 1rem
    overflow-x: auto
    width: 100%
    thead
      th
        background: $primary!important
        color: white!important
    .ui.table thead tr:first-child > th
      position: sticky !important
      top: 0
      z-index: 2
  .invetory-pagination
    margin: 1rem 0
    display: flex
    justify-content: center

@media only screen and (max-width: 768px)
  .inventory
    .inventory-table
      height: max-content
    .search-form
      .field
        margin-bottom: 1em!important
