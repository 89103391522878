@import "../../../../sources/mixins"
  
.swiperAligner
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 12px

  .swiperWrapper
    display: flex
    justify-content: center
    max-width: 1000px

    @include breakpoint('lg')
      max-width: 760px

    @include breakpoint('md')
      max-width: 460px

    @include breakpoint('sm')
      max-width: 300px

    .slideWrapper
      display: flex
      justify-content: center
      height: fit-content
