.dealer-agreement
  h2
    text-align: center
  p, span
    text-align: justify
    color: black
  .footer
    margin-top: 2rem
    small, .page
      text-align: right
      display: block
    .contact
      text-align: center
  .under-line
    text-decoration: underline
  .d-block
    display: block
