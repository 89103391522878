@import "../globals"
@import "../../../sources/mixins"

.headerContainer
  padding: 0 52px

  @include breakpoint('xl')
    padding-inline: 28px

  @include breakpoint('md')
    padding-inline: 20px

    @include breakpoint('sm')
      padding-inline: 12px

div.dashHeader
  width: 100%
  border-radius: 16px
  background-color: #ffffff
  box-shadow: 0 4px 6px 4px #d4cbcb
  padding: 16px 0
  height: auto
  display: grid
  align-items: center

  .ui.grid
    justify-content: space-between

    .column
      width: auto !important

  .rightHeader
    display: flex !important
    flex-direction: row

  .required-inut
    color: red

  .title
    >h2
      margin-left: 30px
      margin-top: 0
      color: #003f69
      font-weight: normal

      @include breakpoint('xs')
        margin-left: 12px

    .sub
      font-size: 1rem !important

  .addRetailer
    display: flex
    flex: 1

    button
      margin: auto 20px auto auto

      @include breakpoint('lg')
        margin-right: 0

  .profileName
    float: right
    right: 20px
    padding: 14px 40px 14px 25px
    color: $secondary
    font-weight: bold
    font-size: 1.2rem
    background-color: rgba(255, 255, 255, 0)

    @include breakpoint('lg')
      padding: 14px 28px 14px 20px

.menu-open
  display: none

  button.ui.button
    padding: 0.75rem
    font-size: 2rem
    background: white
    color: $primary
    border-radius: 50%

    &:hover
      background: white
      border-color: transparent
      color: $primary

.newRetailerImage
  text-align: center
  height: 180px

  img
    max-width: 100%
    max-height: 100%

.creditRange
  margin-top: 2em !important

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
  div.dashHeader
    display: grid
    align-items: center

// mobile vertical
@media only screen and (max-width: 768px)
  div.dashHeader
    // height: 15%
    padding: 1rem

    .menu-open
      display: inline-block

    div.tablet.title
      display: flex!important
      justify-content: space-around
      align-items: center

// mobile horizontal
@media only screen and (min-width: 327px) and (max-width: 768px) and (orientation : landscape)
  div.dashHeader
    // height: 25%
    padding: 1rem

    .menu-open
      display: inline-block

    div.tablet.title
      display: flex!important
      justify-content: space-around
      align-items: center
