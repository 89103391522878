@import "../../../utilities/globals"

.header-chroma
    position: relative
    display: flex
    width: 100%
    background: white
    height: 68px
    -webkit-box-shadow: -1px 5px 8px 1px rgba(0,0,0,0.34)
    -moz-box-shadow: -1px 5px 8px 1px rgba(0,0,0,0.34)
    box-shadow: -1px 5px 8px 1px rgba(0,0,0,0.34)
    z-index: 50

    .icon-close
        float: left
        width: 72px
        height: 100%
        font-size: 40px
        text-align: center
        background: $primary

    .icon-grapper
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        height: 100%

        i
            color: white!important
            display: flex
            justify-content: center
            align-items: center

    .images-grapper
        width: 92%
        height: 100%
        display: inline-block
        // border-radius: 100px 0px 0px 100px
        overflow: hidden

        .logo
            background: white
            width: 50%
            // display: inline-block
            float: left
            height: 100%
            border-radius: 0px 0px 50px 0px
            div
                width: 100%
                height: 100%
                display: flex
                // justify-content: center
                align-items: center
                padding: 5px
                img
                    height: 90%
        .button-action
            width: 40%
            // display: inline-block
            float: left
            display: flex
            height: 100%
            align-items: center
            justify-content: center
            button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
                background: $primary
                color: white
                border-radius: 20px
                border: 1px solid $primary
                &:hover
                    color: $primary
                    background: white
                    border: 1px solid $primary
        .logo-retailer
            width: 40%
            height: 100%
            float: right
            background: white
            margin-right: 10%
            div
                width: 100%
                height: 100%
                display: flex
                justify-content: center
                align-items: center
                padding: 5px
                img
                    width: 70%

//tablet horizontal
@media only screen and (min-width: 991px)
    div.header-chroma
        // height: 10%
        .images-grapper
            .logo
                width: 50%
            .button-action
                display: flex
            .logo-retailer
                width: 40%
// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    div.header-chroma
        // height: 10%
        .images-grapper
            .logo
                width: 50%
                div
                    img
                        height: 90%
            .button-action
                display: flex
            .logo-retailer
                width: 40%
                div
                    img
                        width: 90%

// mobile vertical
@media only screen and (max-width: 768px)
    div.header-chroma
        // height: 10%
        .images-grapper
            .logo
                width: 50%
                div
                    img
                        height: 90%
            .button-action
                display: none
            .logo-retailer
                width: 38%
                div
                    img
                        width: 100%

@media screen and (max-width: 327px)
    div.header-chroma
        // height: 10%
        .images-grapper
            .logo
                width: 50%
                div
                    img
                        height: 90%
            .button-action
                display: none

// // small mobile vertical
@media only screen and (min-width: 327px) and (orientation : landscape)
    .header-chroma
        // height: 20%
        .images-grapper
            .logo
                width: 40%
                div
                    img
                        height: 90%
            .button-action
                display: none
            .logo-retailer
                width: 40%
                div
                    img
                        width: 70%
