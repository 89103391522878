@import "../../utilities/globals.sass"
@import "../../../sources/mixins"

.customInputWrapper
  position: relative
  margin-bottom: 1rem
  min-height: 40px
  border: 1px solid #d7d7d7
  border-radius: 9px
  -webkit-box-shadow: 0 0 0 2px transparent
  -moz-box-shadow: 0 0 0 2px transparent
  box-shadow: 0 0 0 2px transparent
  transition: all 0.3s

  &:not(.danger)
    @include hover()
      border-color: $primary

  &:not(.danger):focus-within
    -webkit-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    -moz-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    border-color: $primary

  .ui.right
    div.label
      border-radius: 0 9px 9px 0 !important
      border-left: 1px solid #d7d7d7 !important
      border-right: none !important

  div.label
    position: relative
    background-color: #fff
    border-radius: 9px 0 0 9px !important
    border: none !important
    border-right: 1px solid #d7d7d7 !important

  input
    border: none !important
    border-radius: 9px !important
    min-height: 38px
    min-width: 6rem !important

  .errorMessage
    position: absolute
    left: 0
    top: 2.9rem
    display: inline-block
    font-size: 0.875rem
    line-height: 1
    color: $negative
    opacity: 0
    transition: all 0.3s

    @include breakpoint('lg')
      top: 3.1rem
      font-size: 0.75rem

  .errorMessageVisible
    opacity: 1

.withoutMargin
  margin-bottom: 0

.ghost
  border-color: transparent !important

  div.label
    border: none !important

.danger
  border-color: $negative

  &:focus-within
    -webkit-box-shadow: 0 0 0 2px rgba($negative,0.3)
    -moz-box-shadow: 0 0 0 2px rgba($negative,0.3)
    box-shadow: 0 0 0 2px rgba($negative,0.3)
