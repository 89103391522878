@import  "../globals"

.buyer-modal
    background: white!important

    .content
        background: none!important

        .title-info
            color: $tertiary
            text-align: center

        .customForm
            padding: 1rem 0rem 0 2rem

            label
                color: $primary!important
                font-weight: bold

            input
                color: $tertiary !important

    .actions
        text-align: center!important
        background: none!important
        border: none!important

        button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
            background: $secondary
            color: white
            border-radius: 20px
            border: 1px solid $secondary
            &:hover
                color: $secondary
                background: white
                border: 1px solid $secondary

.buyerInfoSectionWrapper
    .fields
        margin-bottom: 0 !important

    .headerWrapper
        display: flex
        align-items: center
        gap: 12px
        margin-bottom: 1rem

        > h3
            margin: 0

        > svg
            width: 24px
            height: 24px
