@import "../../../globals"
.incentive-program
  h2
    text-align: center
  .title
    span
      color: $positive!important
  .sub-title
    color: $primary!important
  .examples
    span
      color: $positive!important
  p, .sub-title
    text-align: justify
  p, span
    color: black