@import "../../../sources/mixins"
      
.codeModal
    .codeModalActions
        > button
            @include breakpoint('md')
                margin-bottom: 0 !important

    .resend
        cursor: pointer

    .resend-button
        margin: 0.5rem 0 !important
        text-decoration: none!important
