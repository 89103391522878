@import  "../../globals"
@import "../../../../sources/mixins"

.edit-car
  @include breakpoint('lg')
    div.ui.grid
      flex-direction: column
      align-items: center

      .column
        width: 100% !important

        &:first-child
          width: 90% !important

  @include breakpoint('md')
    .carouselWrapper div
      width: 100%

  .carDetailsWrapper
    flex-wrap: nowrap !important

  .carouselWrapper
    min-width: 380px

    @include breakpoint('lg')
      min-width: 300px

    @include breakpoint('md')
      .carouselImageList
        width: 100%

    .slider
      .slide
        padding: 0.25rem

        @include breakpoint('sm')
          height: fit-content

        img
          height: 300px
          object-fit: contain

          @include breakpoint('lg')
            height: 260px

      .thumbs-wrapper
        height: 68px

      .thumbs
        height: 120px

        .thumb
          img
            object-fit: contain
            width: 70px
            height: 52px

  .content
    min-height: 65vh
    overflow-y: auto
  .button-edit-wrapper
    width: 100%
    text-align: right
  .btn-edit-sections.ui.button
    width: 100%
    background: $primary
    color: white
    border: 1px solid $primary
    position: relative
    margin-bottom: 10px
    .icon
      position: absolute
      right: 5px
      font-size: 14px
    &:hover
      color: $primary
      background: white
  .disabled
    opacity: 1!important
  .field.disabled, .disabled
    label
      opacity: 1!important
    input[type="text"]:disabled, input[type="email"]:disabled, .selection, textarea
      outline: none
      padding: 0 0 5px 0
      box-shadow: none!important
      opacity: 1
      padding-left: 0!important
      padding-right: 0!important
      border: none!important
      background: none!important
      .dropdown
        display: none
    input, select, textarea
      border: none
      padding-left: 0

  .formWrapper
    .ui.form .fields .field:not(.formTextarea)
      @include breakpoint('md')
        margin-bottom: 1rem !important

  .formTextarea textarea
    resize: none !important
