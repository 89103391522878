@import  "../../utilities/globals"
@import "../../../sources/mixins"

.login-background
    // background-image: url("../../../images/background-chromacars.png")
    background: white
    padding: 1rem
.login
    text-align: center
    display: table
    // justify-content: center
    // align-items: center
    width: 100%
    height: 100%

    .login-content
        width: 100%
        display: table-cell
        vertical-align: middle
        .login-body
            max-width: 550px
            margin: auto
            .title, .title-or
                color: $tertiary
                font-weight: normal
            .title-or
                font-weight: bold
                margin-top: 1rem!important
            .form-login
                input
                    border-color: $primary!important
                ::-webkit-input-placeholder
                    text-align: center
                :-moz-placeholder // Firefox 18- */
                    text-align: center
                ::-moz-placeholder  // Firefox 19+ */
                    text-align: center
                :-ms-input-placeholder
                    text-align: center
            button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
                background: $primary
                color: white
                border-radius: 20px
                border: 1px solid $primary
                &:hover
                    color: $primary
                    background: white
                    border: 1px solid $primary
            p.p-forgot
                color: $primary
                text-decoration: underline
                text-decoration-color: $primary
                cursor: pointer
                margin: 1rem 0
            .register-now
                color: $tertiary
                padding-bottom: 1rem
                cursor: pointer
                span
                    color: $secondary!important
                    text-decoration: underline
                    text-decoration-color: $secondary

    .prequalifiedTitle
        font-size: 1.7rem !important
        font-weight: 700 !important
        color: $primary !important

        @include breakpoint('xl')
            font-size: 1.5rem !important

    button.prequalifiedButton
        @include breakpoint('md')
            width: auto !important

            h3
                font-size: 1rem

.buttonWrapper
    display: flex
    justify-content: center

.buttonsWrapper
    display: flex
    justify-content: center
    gap: 12px

//tablet horizontal
@media only screen and (min-width: 991px)
    // div.login
    //     display: flex!important
//tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    // .login
    //     display: flex
// mobile vertical
@media only screen and (max-width: 768px)
    div.flex
        // display: flex
        padding: 1rem
    div.login
        // display: flex
        .login-content
            .login-body
                min-width: inherit
                width: 100%
            .form-login
                input
                    border-radius: 20px!important
            button.btn-continue
                width: 85%
            .footer
                font-size: 11px

@media screen and (max-width: 368px)
    div.login
        // display: block
// small mobile vertical
@media only screen and (min-width: 327px) and (orientation : landscape)
    div.login
        // display: block
