.avatarWrapper
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%
  
  > span
    font-size: 16px
    font-weight: 500
    color: rgba(#000, 0.5)
