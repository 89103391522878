@import  "../../utilities/globals"
.wrapper-password
    .title
        color: $tertiary
        font-weight: 400
    label
        color: $tertiary!important
    .required-inut
        color: red
    button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
        background: $primary
        color: white
        border-radius: 20px
        border: 1px solid $primary
        border-radius: 18px !important
        font-size: 1.2rem !important
        padding: 17.5px 35px !important
        font-weight: 500 !important
        &:hover
            color: $primary
            background: white
    .field
        margin-bottom: 1em!important