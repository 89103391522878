@import "../globals"
@import "../../../sources/mixins"

div.dashboardMenu.close-menu
  left: -100%

div.overlay-menu
  position: fixed
  top: 0
  bottom: 0
  width: 100%
  left: -100%
  background-color: transparent
  z-index: 9
  transition: 200ms background-color

  .arrow-close
    font-size: 4rem
    background: white
    color: $primary
    padding: 0!important
    margin: 0
    position: absolute
    right: 1rem
    top: 50%
    display: block

    &:hover
      background: white
      color: $primary

.show-overlay
  left: -100%

div.dashboardMenu
  overflow-y: auto
  overflow-x: hidden
  display: flex
  flex-direction: column
  box-shadow: 0px 0px 30px 20px #d4cbcb
  z-index: 10
  background: white
  position: static
  transition: 400ms all

  &::-webkit-scrollbar
    display: none

  .dashImage
    min-height: 80px
    display: flex
    align-items: center
    justify-content: center
    margin: 0.5rem 0

    @include breakpoint('md')
      min-height: 72px

    img
      width: 70px

      @include breakpoint('md')
        width: 56px

  .customMenu
    flex: 1
    border: none
    box-shadow: none !important
    border: 0 !important
    position: relative
    display: flex !important
    padding: 0.5rem
    gap: 4px

    @include breakpoint('xl')
      max-width: 200px !important

    span
      color: $primary
      font-size: 1rem

    .bottomMenu
      margin-top: auto

    .item.termCondictions
      margin-bottom: 0.5rem
      font-size: 0.9em
      padding: 0!important

      a
        height: 46px
        padding: 6px 10px!important
        color: $primary!important
        display: flex
        align-items: center
        justify-content: center
        text-align: center

      &:hover
        a
          color: white!important

    .svgContainer
      text-align: center

      svg
        @include squareShape(32px)

        @include breakpoint('xl')
          @include squareShape(28px)

        @include breakpoint('md')
          @include squareShape(24px)

      path, line
        fill: $primary

    .item
      display: flex !important
      align-items: center
      gap: 12px
      padding: 0.5rem 0.75rem !important
      border-radius: 7px !important
      transition: all 0.3s

      > span
        font-size: 1.25rem

        @include breakpoint('xl')
          font-size: 1rem

      &:hover path
        fill: #FFF
      &:hover span
        color: #FFF
      &:hover
        background-color: $primary !important
        width: 100%
        -webkit-box-shadow: 0 0 23px -11px rgba(0,0,0,0.75) !important
        -moz-box-shadow: 0 0 23px -11px rgba(0,0,0,0.75) !important
        box-shadow: 0 0 23px -11px rgba(0,0,0,0.75) !important

    .logOut
      justify-content: center

    .active
      background-color: $primary !important
      width: 100%

      path
        fill: #FFF
      span
        color: #FFF
// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
  .App
    div.ui-mobile.show-overlay, button.ui-mobile.arrow-close
      display: block!important
// mobile vertical
@media only screen and (max-width: 768px)
  div.dashboardMenu
    position: absolute
    top: 0
    bottom: 0
    left: 0
    box-shadow: 0px 0px 30px 10px #444
  div.show-overlay
    left: 0
    background-color: $popupBackground
// mobile horizontal
@media only screen and (min-width: 327px) and (orientation : landscape)
  .dashboardMenu
    position: absolute
  div.show-overlay
    left: 0
    background-color: $popupBackground
