@import "./components/utilities/globals"
@import "./components/utilities/carousel"
@import "./sources/mixins"

.ui-computer
    display: block

.ui-mobile
    display: none!important

.App
    width: 100%
    height: 100%
    overflow: hidden

    .ui-mobile
        display: none

    .ui-computer
        display: block

    div.cursor-pointer, button.cursor-pointer, .cursor-pointer
        cursor: pointer!important

    .container .quot-content
        div.cursor-pointer, button.cursor-pointer, .cursor-pointer
            cursor: pointer!important

    .loading
        position: fixed!important
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 100000000!important

    .loading.ui.dimmer
        background: $popupBackground!important

    .ui.form input:not([type]), .ui.form input[type=email],
    .ui.form input[type=file],
    .ui.form input[type=number],
    .ui.form input[type=password],
    .ui.form input[type=text], .ui.form input, .ui.selection, input, select, , .ui.form textarea
        // border-radius: 0
        // border: 1px solid rgba(34,36,38,.15)
        // box-shadow: 0 0 8px -3px #666

.loading
    position: fixed!important
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 100000000!important

.loading.ui.dimmer
    background: $popupBackground!important

.required-inut
    color: red

div.dashboard
    width: 100%
    height: 100%
    display: flex
    flex-direction: row
    margin-left: auto
    margin-right: auto

    .customDatePicker
        display: flex
        align-items: center
        background-color: transparent
        border-radius: 7px
        padding: 1rem 1.5rem
        margin: 0
        color: #000000ce
        transition: all 0.3s

        @include breakpoint('md')
            padding: 0.75rem 1rem
            margin-left: 0

            span
                font-size: 1rem

        i
            margin-top: -5px
            margin-right: 0 !important
            font-size: 1.5em

        &:hover,
        &:focus
            background-color: white

    .dashboardContent
        position: relative
        flex: 1
        display: grid
        flex-direction: column
        padding: 30px 0
        grid-auto-rows: max-content auto

        @include breakpoint('xl')
            padding: 24px 0

        .body
            width: 100%
            margin-top: 25px
            padding-inline: 52px
            height: auto
            flex: 1
            overflow-y: auto
            overflow-x: hidden

            @include breakpoint('xl')
                padding-inline: 28px

            @include breakpoint('md')
                padding-inline: 20px

            @include breakpoint('sm')
                padding-inline: 12px

            .grid
                margin: 0

                .column
                    @include breakpoint('xl')
                        width: 100% !important

.generalInfo
    background-color: #FFF!important
    display: flex!important

    @include breakpoint('lg')
        justify-content: center

    @include breakpoint('sm')
        flex-direction: column
        align-items: center

    .customStatistic
        width: max-content
        min-width: 150px
        text-align: center
        padding: 25px
        border-radius: 20px
        box-shadow: 1px 1px 5px #979494
        transition: 200ms all

        @include breakpoint('lg')
            width: 100% !important

        &:not(:first-child)
            margin-left: 20px

            @include breakpoint('sm')
                margin-left: 0

        div
            height: 100%
            color: #FFF!important
            font-weight: 900
            letter-spacing: 3px

div.dashboard, .ui.modal
    .customTable
        background-color: rgba(255, 255, 255, 0)
        border-collapse: separate
        border-spacing: 0 10px
        border-width: 0
        th, td
            background-color: rgba(255, 255, 255, 1)
            border: none!important
            &:first-child
                border-top-left-radius: 24px
                border-bottom-left-radius: 24px
            &:last-child
                border-top-right-radius: 24px
                border-bottom-right-radius: 24px
        tr, tr.active
            box-shadow: 0 0 4px #d4cbcb
            border-radius: 24px
        thead
            @include breakpoint('md')
                margin: 1rem 0

            .upperHeader
                @include breakpoint('md')
                    padding-bottom: 0
                    padding-top: 0

                th
                    background-color: $darkGray
                    color: #FFF

                    &:first-child
                        border-top-left-radius: 24px
                        border-bottom-left-radius: 24px

                    &:last-child
                        border-top-right-radius: 24px
                        border-bottom-right-radius: 24px
            tr:last-child
                &:not(.upperHeader)
                    box-shadow: none
                    th
                        background-color: rgba(255, 255, 255, 0)
                        color: $tertiary
                        padding-bottom: 0
                        padding-top: 8px
        td, th
            &:first-child
                padding-left: 24px
            &:last-child
                padding-right: 24px

.height-credit-table
    .table-wrapper
        height: 40vh

.transparent
    background-color: rgba(255, 255, 255, 0) !important

.modals
    background-color: $popupBackground !important
    justify-content: center!important
    .modal
        // border-radius: 40px !important
    .customModal
        // border-radius: 40px !important
    div.ui.modal:not(.codeModal)
        @include breakpoint('xl')
            width: 80%

    div.ui.modal>.actions
        display: flex
        justify-content: end
        gap: 0.75rem
        padding: 1em

    div.ui.modal>.close
        top: 1rem
        right: 1rem
        color: inherit

.customForm, .modals
    label, input
        color: $tertiary !important
    .dropdown
        .item, .text
            color: $tertiary !important

    .ui.form input:not([type]), .ui.form input[type=email],
    .ui.form input[type=file],
    .ui.form input[type=number],
    .ui.form input[type=password],
    .ui.form input[type=text], .ui.form input, .ui.selection, .ui.dropdown, textarea, .ui.form textarea, .ui.input input
        // border-radius: 0!important
        // border: 1px solid rgba(34,36,38,.15)
        // box-shadow: 0 0 8px -3px #666

.customForm
    .imageContainer
        display: flex !important
        flex-direction: column
        // justify-content: space-between

        .imageInput div.ui.input input
            padding: 6.5px 14px

/* ---------------------------- Pagination --------------------  */
.pagination
    flex-wrap: wrap
    background: none!important
    border: none!important
    box-shadow: inherit!important
    a
        color: black!important
        border: 1px solid rgba(34,36,38,.15)
        background: white!important
    a.active
        background: $primary!important
        color: white!important
.overflow-x
    overflow-x: auto

.ui.button.btn-detail, .ui.button.btn-detail:active, .ui.button.btn-detail:hover, .ui.button.btn-detail:focus
    background: $secondary
    border-radius: 20px
    color: white

.tooltip-button
    cursor: pointer
    color: $primary
    display: block

// ---------------------------------------- primaries buttons
.ui.primary.button, .ui.blue.button
    background: $primary!important
    border: 1px solid $primary!important
    color: white!important
    &:hover
        background: white!important
        color: $primary!important
.ui.inverted.primary.button, .ui.inverted.blue.button
    border: 1px solid $primary!important
    box-shadow: 0 0 0 2px $primary inset!important
    color: $primary!important
    background: white!important
    &:hover
        background: $primary!important
        color: white!important

.ui.button.secondary
    background: $secondary!important
    border: 1px solid $secondary!important
    color: white
    &:hover
        color: $secondary!important
        background: white !important
.ui.inverted.button.secondary
    background: white!important
    border: 1px solid $secondary!important
    box-shadow: 0 0 0 2px $secondary inset!important
    color: $secondary!important
    &:hover
        color: white!important
        background: $secondary !important

.ui.positive.button, .ui.green.button
    border: 1px solid $positive!important
    background: $positive!important
    &:hover
        color: $positive!important
        background: white!important
.ui.inverted.positive.button, .ui.inverted.green.button
    background: white!important
    border: 1px solid $positive!important
    box-shadow: 0 0 0 2px $positive inset!important
    color: $positive!important

    &:hover
        color: white!important
        background: $positive!important

.ui.red.button, .ui.negative.button
    border: 1px solid $negative!important
    &:hover
        color: $negative!important
        background: white!important
.ui.inverted.red.button, .ui.inverted.negative.button
    background: white!important
    border: 1px solid $negative!important
    box-shadow: 0 0 0 2px $negative inset!important
    color: $negative!important
    &:hover
        color: white!important
        background: $negative!important

.ui.button
    border-radius: 18px
    font-size: 1.2rem
    font-weight: 500

.ui.modal>.actions
    @include breakpoint('md')
        padding: 1em !important

.actions
    .ui.button
        font-size: 1.2rem !important
        padding: 17.5px 30px !important
        font-weight: 500 !important

        @include breakpoint('xs')
            font-size: 1rem !important
            padding: 12px 16px !important

.scrolling.dimmable>.dimmer
    justify-content: flex-start

i.blue.icon, .ui.steps .step.active .title
    color: $primary!important

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    .App
        div.ui-mobile, .ui-mobile
            display: none!important
        div.ui-computer
            display: block!important
        .fields
            margin-bottom: 1em !important
    div.ui-mobile, .ui-mobile
        display: none!important
    div.ui-computer
        display: block!important

@media only screen and (max-width: 768px)
    .modals
        div.ui.modal>.content
        padding: 1.5rem !important
        div.ui.modal>.actions
        padding: 1rem !important

    .App
        div.ui-mobile, .ui-mobile
            display: block!important
        div.ui-computer
            display: none!important
        .fields
            margin-bottom: 1em !important
        .ui.table.last-deals
            tr
                td
                    display: inline !important
    div.ui-mobile, .ui-mobile
        display: block!important
    div.ui-computer
        display: none!important
    div.dashboard
        .dashboardContent
            display: block
            width: 100%
            padding: 1rem 0
            .body
                height: 80%
    .generalInfo
        .customStatistic
            width: 100%
            margin: 5px 0
            .value
                font-size: 2em!important
            .widthQuoteDetail
                max-width: 100%
// mobile horizontal
@media only screen and (min-width: 327px) and (max-width: 768px) and (orientation : landscape)
    .App
    div.dashboard
        .dashboardContent
            .body
                height: 70%
                .widthQuoteDetail
                    max-width: 100%
