@import "../../utilities/globals"
@import "../../../sources/mixins"

.container
    // padding:  30px 0
    height: 100%
    width: 100%

    .quot-content
        height: 90%
        width: 100%
        padding: 2rem
        overflow-y: auto
        position: relative
        overflow-x: hidden
        // background-image: url("../../../images/background-chromacars.png")
        .a2uWeekly
            color: $primary
            i
                font-size: 1em
                position: relative
                top: -2px
        .congratulations-text
            color: $positive
            margin: 15px 0!important

            i
                font-size: 1.75rem
                vertical-align: top

        .change-car-text
            font-weight: normal
            color: $primary
            b
                font-weight: bold
        .blue-text
            color: $primary
        .grapperButtons
            width: 100%
            text-align: center
            margin: 25px 0px

            @include breakpoint('md')
                h3
                    font-size: 1rem

        .customButtonWrapper
            display: flex
            justify-content: center

        .PreQualified
            .ui.container
                width: 100%

        .description-slider
            color: $tertiary
            text-align: justify
            width: 100%
            h2, h3
                font-weight: normal
                text-align: center
        .insurance-protection
            width: 100%
            border: 2px solid $tertiary
            border-radius: 8px
            margin: 10px 0
            .field
                display: inline-block
            i
                display: inline-block
                color: $tertiary
            .checkbox
                padding: 5px 5px 5px 50px!important
                width: 100%
            label
                color: $tertiary!important
        .color-blue-ligth
            color: $primary
        .register-grapper
            height: 100%
            width: 100%
            display: table
            // align-items: center
            .register-title
                position: absolute
                top: 0
                left: 0
                right: 0
                box-shadow: -1px 5px 8px 1px rgba(0,0,0,0.34)
                padding: 1rem
                background: white
            .register-background
                background: white
                padding: 2rem
                border-radius: 10px
                -webkit-box-shadow: -2px -1px 21px -7px rgba(0, 0, 0, 0.59)
                -moz-box-shadow: -2px -1px 21px -7px rgba(0, 0, 0, 0.59)
                box-shadow: -2px -1px 21px -7px rgba(0, 0, 0, 0.59)
        button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
            background: $primary
            color: white
            border-radius: 20px
            border: 1px solid $primary
            &:hover
                color: $primary
                background: white
                border: 1px solid $primary

        .borderFinalWrapper
            padding: 1rem
            width: 50%

            @include breakpoint('xl')
                padding: 0.5rem

            @include breakpoint('lg')
                padding: 1rem

            @include breakpoint('md')
                padding: 1.25rem
                width: 100%

        .borderFinal
            height: 100%
            border: 1px solid $primary
            padding: 2rem!important
            border-radius: 8px

            @include breakpoint('lg')
                padding: 1rem !important

        .headerColumn
            display: flex !important
            justify-content: center
            align-items: center
            height: 80px

            h3
                text-align: center !important

.infoIcon
    color: $primary
    width: 2rem
    height: 2rem

.carWrapper
    .accordionWrapper
        padding-inline: 1rem

    .slider
        .slide
            img
                padding: 0.25rem
                height: 360px
                object-fit: contain

                @include breakpoint('lg')
                    height: 320px

                @include breakpoint('md')
                    height: 300px

                @include breakpoint('sm')
                    height: 260px

    .thumbs
        .thumb
            img
                object-fit: contain
                width: 70px
                height: 52px

.previewWrapper
    display: flex
    flex-direction: column

    @include breakpoint('md')
        margin-top: 1rem

    .carComponent
        .ui.grid
            @include breakpoint('md')
                flex-direction: column

            > div
                &:nth-child(1),
                &:nth-child(2)
                    width: 50% !important

                    @include breakpoint('md')
                        width: 100% !important

    .actionAndVideoWrapper
        display: flex
        justify-content: space-between
        align-items: center
        gap: 1rem

        @include breakpoint('md')
            flex-direction: column
            gap: 0

        > div
            width: 50%

            @include breakpoint('md')
                margin-top: 0.5rem
                padding: 0.5rem 0
                width: 100%

            button.btn-continue
                width: 100% !important

                h3
                    @include breakpoint('lg')
                        font-size: 1rem

                    @include breakpoint('md')
                        font-size: 0.875rem

            h2.ui.header
                @include breakpoint('lg')
                    font-size: 1.5rem

                @include breakpoint('md')
                    font-size: 1.25rem

.conditionsButton
    display: flex
    justify-content: center
    flex: 1

    @include breakpoint('md')
        > div
            width: 100%
            padding-inline: 0.5rem

    button
        @include breakpoint('md')
            width: 100% !important
            margin: 0 !important

        h3
            @include breakpoint('lg')
                font-size: 1rem

            @include breakpoint('md')
                font-size: 0.875rem

.paymentPlansWrapper
    @include breakpoint('lg')
        display: none !important

    .grapperPayments > div.column
        @include breakpoint('xl')
            padding: 0.5rem !important

.paymentMobileWrapper
    display: none

    @include breakpoint('lg')
        display: block

.similarCarsWrapper
    display: none

    @include breakpoint('lg')
        display: block

.weeklyValue
    display: flex
    justify-content: center
    align-items: center
    margin: 1.5rem 0
    width: 100%

    > span
        display: flex
        justify-content: center
        align-items: center
        gap: 8px
        width: 260px
        height: 44px
        color: #fff !important
        border-radius: 9px !important
        background: rgb(24,0,115)
        background: linear-gradient(45deg, rgba(24,0,115,1) 0%, rgba(89,53,215,1) 100%)
        font-size: 20px
        font-weight: 700

        @include breakpoint('md')
            font-size: 16px

        > i
            color: #fff

.disclaimerWrapper
    max-height: 250px
    overflow-y: auto

    &::-webkit-scrollbar-track
        background-color: #fff

    > a
        color: #000
        text-decoration: underline
        transition: color 0.4s

        @include hover()
            color: $primary
            cursor: pointer

.createAccountButtonWrapper
    @include breakpoint('xl')
        margin-bottom: 1rem !important

//tablet horizontal
@media only screen and (min-width: 991px)
    div.container
        .quot-content
            height: 90%
            padding: 2rem
            div.register-grapper
                // display: flex
                .register-background
                    padding: 2rem

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    div.container
        .quot-content
            padding: 2rem
            height: 90%
            .grapperButtons
                margin: 20px 0 0px 0
            button.quotContinue
                width: 50%
                padding: 10px 20px
            div.register-grapper
                // display: flex
                .register-background
                    padding: 2rem
            .grapperPayments
                h3.title-payments
                    .icon-payments
                        // width: 20vw

// mobile vertical
@media only screen and (max-width: 768px)
    div.container
        .quot-content
            padding: 1rem
            height: 90%
            .container-slider
                padding-left: 1rem
                padding-right: 1rem
            .grapperButtons
                margin: 20px 0 0px 0
            button.btn-continue
                width: 85%
                margin-bottom: 10px
            div.register-grapper
                // display: block
                .register-background
                    padding: 2rem 1rem
            .grapperPayments
                h3.title-payments
                    .icon-payments
                        // width: 45vw

@media screen and (max-width: 327px)
    div.container
        .quot-content
            height: 90%
            div.register-grapper
                // display: block
                .register-background
                    padding: 2rem 1rem
// small mobile vertical
@media only screen and (min-width: 327px) and (orientation : landscape)
    .container
        .quot-content
            height: 80%
            div.register-grapper
                // display: block
                .register-background
                    padding: 2rem 1rem
