@import "../globals"
@import "../../../sources/mixins"

.variablesModal
    background: white!important

    .variablesForm
        display: flex
        flex-direction: column
        gap: 20px

        .sectionWrapper
            border: 1px solid #d7d7d7
            background-color: #fff

    .modalTitle
        margin-top: 40px
        text-align: center
        color: $tertiary
        font-size: 2.7rem

    .modalContent
        input, .ui.form input:not([type])
            box-shadow: 0 0 8px -3px #666
        label
            color: $tertiary!important
            font-weight: 700!important
            width: 100%
            display: block
        .fields
            display: flex
            align-items: stretch
            margin-bottom: 0!important

        .saveButtonWrapper
            margin-bottom: 0 !important

            @include breakpoint('md')
                width: 100% !important

        .saveButton
            padding: 1.5rem 0 0

            @include breakpoint('md')
                text-align: end

            > button
                padding: 12px 20px !important

        .field
            display: flex
            flex-direction: column
            justify-content: space-between
            margin-bottom: 1em!important

            .field
                margin-bottom: 0!important
        .disabled-check
            opacity: 1
            .disabled, input, label
                opacity: 1!important
        ::-webkit-input-placeholder
            color: transparent
        :-moz-placeholder
            color: transparent
        ::-moz-placeholder
            color: transparent
        :-ms-input-placeholder
            color: transparent
        input::placeholder
            color: transparent
        .customButton
            padding: 0 !important
            margin-top: 20px
        button
            border-radius: 18px !important
            font-size: 1.2rem !important
            padding: 17.5px 35px !important
            font-weight: 500 !important

        .acceptButton, button.acceptButton:active, button.acceptButton:focus
            background-color: $secondary
            color: white
            border: 1px solid $secondary
            &:hover
                background-color: white
                color: $secondary
        .editButton, button.editButton:active, button.editButton:focus
            background-color: $primary
            color: white
            border: 1px solid $primary
            &:hover
                background-color: white
                color: $primary
        .cancelButton, button.cancelButton:active, button.cancelButton:focus
            background-color: red
            color: white
            border: 1px solid red
            &:hover
                background-color: white
                color: red
        .closeButton, button.closeButton:active, button.closeButton:focus
            background-color: $tertiary
            color: white
            border: 1px solid $tertiary
            &:hover
                color: $tertiary
                background-color: white

    .rateSection
        input
            width: 100% !important

    .headerWrapper
        display: flex
        align-items: center
        gap: 12px
        margin-bottom: 1rem

        > h3
            margin: 0

        > svg
            width: 24px
            height: 24px
