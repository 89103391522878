@import "../globals"
@import "../../../sources/mixins"

div.PreQualified
  div.container-slider
    padding: 0
  div.segmentGrapper
    width: 100%
    box-shadow: none!important
    padding: 0 1em!important
    .sliderGrapper
      padding: 0 10px!important
  div.header-slider
    display: flex
    justify-content: space-between
    align-items: center
    label
      font-size: .92857143em
      font-weight: 700
  .required-inut
    color: red
  .d-block
    display: block
  .m-t-3
    margin-top: 10px
  .title-a2u
    text-align: center

    .title
      margin-bottom: 0
      font-weight: bold

    .a2uMonthly, .orText
      color: $darkGray
      margin: 5px 0

      @include breakpoint('xl')
        font-size: 1rem !important

    .a2uWeekly
      color: $primary
      margin: 5px 0

      @include breakpoint('xl')
      font-size: 1.3rem !important

      i
        margin-left: 5px

.top
  display: flex
  align-items: center
  justify-content: space-between
  gap: 12px

  @include breakpoint('xl')
    h1
      font-size: 1.75rem !important

    h2
      font-size: 1.5rem !important

  @include breakpoint('md')
    padding: 1rem 0.5rem 0
    flex-direction: column

    h1
      margin-top: 0.5rem !important

  > div
    width: 400px

    @include breakpoint('md')
      width: 300px

.infoSection
  display: flex
  align-items: center
  gap: 12px
  width: 100%

  @include breakpoint('sm')
    flex-direction: column

  @include breakpoint('md')
    padding: 0 2rem

  @include  breakpoint('sm')
    padding: 0

  > div
    padding: 0.5rem

    &:first-child
      flex: 1

      h2
        margin-bottom: 0.75rem !important

      .subtitle
        margin-bottom: 1.55rem !important

        @include breakpoint('sm')
          margin-bottom: 0 !important

      @include breakpoint('xl')
        h2
          font-size: 1.5rem !important

        .subtitle
          font-size: 1rem !important

    &:last-child
      width: 400px

      @include breakpoint('lg')
        width: 300px

      @include breakpoint('md')
        width: fit-content

.actionSection
  display: flex
  flex-wrap: nowrap
  width: 100%

  @include breakpoint('md')
    flex-direction: column-reverse

  .leftSideWrapper,
  .rightSideWrapper
    padding: 0.5rem

  .leftSideWrapper
    flex: 1

    .ui.menu
      border: none

      > a.item
        text-align: center
        justify-content: center
        align-self: center
        width: 50%
        transition: all 0.3s !important
        font-size: 16px
        font-weight: 500
        line-height: 1.25
        border: none

      > a.active.item
        border: none !important
        color: $primary

        &:hover
          color: $primary

    .ui.attached.segment
      margin: 0
      padding: 0
      border: none

  .rightSideWrapper
    max-width: 400px

    @include breakpoint('lg')
      max-width: 300px

    @include breakpoint('md')
      // display: flex
      // justify-content: center
      max-width: 100%

      > div
        display: flex
        justify-content: center

  .carouselWrapper
    min-width: 380px

    @include breakpoint('xl')
      min-width: 320px

    @include breakpoint('lg')
      min-width: 300px

    @include breakpoint('md')
      .carouselImageList
        width: 100%

    @include breakpoint('xs')

    .slider
      .slide
        padding: 0.25rem

        @include breakpoint('sm')
          height: fit-content

      img
        height: 300px
        object-fit: contain

        @include breakpoint('lg')
          height: 260px

    .thumbs-wrapper
      height: 68px

    .thumbs
      height: 120px

      .thumb
        img
          object-fit: contain
          width: 70px
          height: 52px

// mobile vertical
@media only screen and (max-width: 768px)
  .PreQualified
    div.prequalified-container
      margin: 0!important
      padding: 0.5rem!important
      width: 100% !important
      .fields
        margin-bottom: 0!important
      .field
        margin-bottom: 1em!important
        .field
          margin-bottom: 0em!important
