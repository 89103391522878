@import  "../../utilities/globals"
@import "../../../sources/mixins"

label
    color: $tertiary!important
.ui.pointing.above.prompt.label
    background: none #fff!important
    color: #db2828!important
    border: 1px solid #db2828!important

.prequalifiedTitle
    font-size: 1.7rem !important
    font-weight: 700 !important
    color: $primary !important

    @include breakpoint('xl')
        font-size: 1.5rem !important

button.prequalifiedButton
    @include breakpoint('md')
        width: auto !important

        h3
            font-size: 1rem

.phoneNumberWrapper
    display: flex
    flex-direction: column
    align-items: center

    div.customInputWrapper
        width: fit-content

.buttonWrapper
    display: flex
    justify-content: center
