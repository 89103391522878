@import "../../utilities/globals.sass"
@import "../../../sources/mixins"

.customButtonWrapper
  button.ui.button
    margin: 0
    width: 100%
    border: 1px solid transparent
    border-radius: 12px
    color: #fff
    transition: all 0.3s

    @include hover()
      background-color: #fff

  a.ui.button
    margin: 0
    background-color: transparent
    border-radius: 12px
    border: 1px solid transparent
    font-size: 1rem
    color: $primary

.skyblue
  button.ui.button
    background-color: $primary

    @include hover()
      border-color: $primary
      color: $primary

.secondary
  button.ui.button
    background-color: #4E55FD

    @include hover()
      border-color: #4E55FD
      color: #4E55FD

.primary
  button.ui.button
    background-color: $positive

    @include hover()
      border-color: $positive
      color: $positive

  a.ui.button
    background-color: $positive
    color: #fff

    @include hover()
      background-color: #fff
      border-color: $positive
      color: $positive

.red
  button.ui.button
    background-color: $negative

    @include hover()
      border-color: $negative
      color: $negative

.brown
  button.ui.button
    background-color: #a5673f

    @include hover()
      border-color: #a5673f
      color: #a5673f

.grey
  button.ui.button
    background-color: #767676

    @include hover()
      border-color: #767676
      color: #767676
