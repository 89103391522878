@import "../../globals"
@import "../../../../sources/mixins"

.quotCardWrapper
  display: flex
  flex-direction: column
  align-items: center
  gap: 12px
  padding: 1.5rem 1rem 1rem
  border: 1px solid #e5e5e5
  border-radius: 7px
  transition: all 0.3s

  @include hover()
    transform: scale(1.05)
    cursor: pointer

  .carImageWrapper
    > img
      height: 160px
      object-fit: contain
      border-radius: 5px

  .quotInfoWrapper
    width: 100%

    .quotInfo
      display: flex
      flex-direction: column
      gap: 4px
      padding: 0
      list-style: none

      .listItem
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0.5rem 1rem

        .carName
          teext-align: center

      .upperInfo
        flex-direction: column
        align-items: center

        > span
          font-weight: 500

          &:nth-child(1)
            font-size: 12px

          &:nth-child(2)
            font-size: 16px

      .nameWrapper
        flex-direction: column

        > span
          &:nth-child(1)
            font-size: 18px
            font-weight: 500

          &:nth-child(2)
            color: #b0b0b0

      .lowerSection
        > span
          font-weight: 500

      .loanDetailsButton
        padding: 0

        .ui.grid
          width: 100%

          > button
            margin: 0
            width: 100%
            border-radius: 7px
