@import "../../../utilities/globals"
@import "../../../../sources/mixins"

.accordionWrapper
  div.active
    font-size: 16px

.linkText
  font-weight: 600
  background-color: #fff
  border: none
  padding: 0
  cursor: pointer
  text-decoration: underline
  transition: color 0.4s

  @include hover()
    color: $primary

.redText
  color: $negative
  cursor: pointer
