@import "../globals"
@import "../../../sources/mixins"

.protectionModal
  max-width: 1100px !important
  width: 100% !important
  border-radius: 10px !important
  overflow: hidden

  @include breakpoint('xl')
    max-width: 90% !important

  .content
    padding: 0 !important
    border-radius: 10px !important
    background-color: rgba($secondary, 0.4) !important

  .modalContent
    position: relative

    .crossIcon
      position: fixed
      top: 28px
      right: 28px
      background-color: #fff
      cursor: pointer
      z-index: 2
      border-radius: 10px

      @include breakpoint('lg')
        top: 20px
        right: 20px

      @include breakpoint('md')
        height: 32px
        top: 16px
        right: 16px

      > button
        padding: 6px
        width: 44px
        height: 44px
        background-color: transparent
        border-radius: 10px
        border: none

        @include breakpoint('md')
          padding: 2px 0
          width: 32px
          height: 32px

        > svg
          stroke: $textColor

          @include breakpoint('md')
            width: 28px
            height: 28px
