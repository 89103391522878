@import  "../../globals"
@import "../../../../sources/mixins"

.polls
  table
    min-width: 480px

  thead
    th
      background: $primary!important
      color: white!important
  .ui.table thead tr:first-child > th
    position: sticky !important
    top: 0
    z-index: 2

  .table-wrapper
    height: 50vh
    overflow-y: auto

  .field
    margin-bottom: 1em!important

  .actionButtons
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    gap: 0.5rem

    .button
      margin-bottom: 0 !important

.actionsContainer
  display: flex

  @include breakpoint('xl')
    flex-direction: column

  > div
    width: 100%

    &:first-child
      display: flex
      justify-content: space-between
      align-items: center
      gap: 0.5rem
      margin-bottom: 0
      margin-right: 0.5rem

      @include breakpoint('xl')
        margin-bottom: 0.5rem

      > div
        width: 100%

        button
          width: 100%

          @include breakpoint('xl')
            padding: 12px 16px !important
            font-size: 1rem

    &:last-child>div
      width: 100%

      button
        width: 100%

        @include breakpoint('xl')
          padding: 12px 16px !important
          font-size: 1rem

.poll-manager
  color: red

  .upperFields
    padding-right: 1rem !important

    .fields
      gap: 1rem

  .fields
    margin-bottom: 0
    .field:not(.checkbox)
      margin-bottom: 1em!important

    .field
      .field
        margin-bottom: 0!important

  .checkbox
    padding-right: 0 !important

    @include breakpoint('md')
      margin-bottom: 1rem

    label
      transition: border-color 0.3s

      &:hover
        &::before
          border-color: $primary !important

      &::before
        border-color: #d7d7d7 !important
        border-radius: 6px !important
      

.importFieldsContainer
  div.fields
    width: 100%
    margin: 0 !important

    .field
      display: flex
      flex-direction: column
      width: 100%

      label
        margin-bottom: 8px !important

      div
        width: 100% !important

.pollSummary
  label
    display: flex
    align-items: center
    height: 100%
