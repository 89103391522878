@import "../../../sources/mixins"

.accounts
  div.customInputWrapper,
  div.customDropdownWrapper,
  div.dateInputWrapper
    margin-bottom: 0

  .buttonsWrapper
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 0.5rem

    div
      @include breakpoint('xs')
        &:first-child
          width: 100%

        &:nth-child(2),
        &:nth-child(3)
          width: calc(50% - 0.25rem)

    .button
      width: 100%
      margin-right: 0 !important

      @include breakpoint('sm')
        font-size: 1rem

  .datesWrapper
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 0.5rem

    > div
      width: calc(50% - 0.5rem)

  .table-wrapper
    height: 45vh
    padding: 0 0rem 0.5rem 1rem
    overflow-x: auto
    overflow-y: auto
    width: 100%

    .customTable
      min-width: 1000px
      border-spacing: 1px 10px

    thead
      background: white!important
      th, tr
        background: white!important
        padding-bottom: 8px
    .ui.table thead tr:first-child > th
      position: sticky !important
      top: -1px
      z-index: 2

@media only screen and (max-width: 768px)
  .accounts
    .table-wrapper
      height: max-content
