.ql-editor
  // li.ql-indent-1:not(.ql-direction-rtl)
  //   padding-left: 4.5em
  // li.ql-indent-2:not(.ql-direction-rtl)
  //   padding-left: 7.5em
  // li.ql-indent-3:not(.ql-direction-rtl)
  //   padding-left: 10.5em
.no-toolbar
  border: none!important
  padding: 0!important
.scrollingEditor
  height: 60vh
  overflow-y: auto

@media only screen and (max-width: 768px)
  .ql-editor
    li.ql-indent-1:not(.ql-direction-rtl)
      padding-left: 2.5em
    li.ql-indent-2:not(.ql-direction-rtl)
      padding-left: 3.5em
    li.ql-indent-3:not(.ql-direction-rtl)
      padding-left: 4.5em
    li.ql-indent-4:not(.ql-direction-rtl)
      padding-left: 5.5em
    li.ql-indent-5:not(.ql-direction-rtl)
      padding-left: 6.5em
    li.ql-indent-6:not(.ql-direction-rtl)
      padding-left: 7.5em
    li.ql-indent-7:not(.ql-direction-rtl)
      padding-left: 8.5em
    li.ql-indent-8:not(.ql-direction-rtl)
      padding-left: 9.5em
    .ql-indent-1:not(.ql-direction-rtl)
       padding-left: 1.5em
    .ql-indent-2:not(.ql-direction-rtl)
       padding-left: 2.5em
    .ql-indent-3:not(.ql-direction-rtl)
       padding-left: 3.5em
    .ql-indent-4:not(.ql-direction-rtl)
       padding-left: 4.5em
    .ql-indent-5:not(.ql-direction-rtl)
       padding-left: 5.5em
    .ql-indent-6:not(.ql-direction-rtl)
       padding-left: 6.5em
    .ql-indent-7:not(.ql-direction-rtl)
       padding-left: 7.5em
    .ql-indent-8:not(.ql-direction-rtl)
       padding-left: 8.5em
  .scrollingEdior
    height: 50vh
