@import "../../../utilities/globals"
@import "../../../../sources/mixins"

.tableWrapper
  position: relative
  margin: 1.5rem 0
  overflow: auto
  border: 1px solid $primary
  border-radius: 5px

  .table
    width: 100%
    border-spacing: 0
    border-collapse: separate

    .tableBody,
    .tableHead
      tr
        td, th
          &:first-child
            position: sticky
            left: 0
            min-width: 280px
            border-right: 1px solid #f0f0f0
            color: $primary
            background-color: #fff
            z-index: 1

            @include breakpoint('md')
              min-width: 160px

            @include breakpoint('sm')
              min-width: 120px

    .tableHead
      tr
        th
          position: relative
          padding: 0.5rem
          border-bottom: 1px solid #f0f0f0

          &:first-child
            font-weight: 400

          &:not(:first-child):not(:last-child)::after
            content: ""
            position: absolute
            top: 20px
            right: 0
            width: 1px
            height: 28px
            background-color: #e9e7e7
            border-radius: 2px

          .paymentPlanSelector
            display: inline-block
            height: 100%
            min-height: 56px
            border-radius: 5px
            border: 1px solid transparent
            outline: none
            background-color: $primary
            color: #fff
            transition: all 0.2s

            &:hover
              border-color: $primary
              background-color: #fff
              color: $primary
              cursor: pointer

            > span
              font-size: 1.25rem

              @include breakpoint('md')
                font-size: 1rem

    .tableBody
      tr
        &:not(:last-child)
          td
            border-bottom: 1px solid #f0f0f0

        &:not(:first-child)
          td
            &:nth-child(2)
              color: $negative

            &:nth-child(3),
            &:nth-child(4)
              font-weight: 500
              color: #00D367

        div.column
          @include breakpoint('md')
            padding: 0.25rem 0.5rem !important

        td
          height: 48px
          padding: 0.5rem
          text-align: center

          @include breakpoint('md')
            height: 36px
            font-size: 12px

          &:not(:first-child)
            min-width: 200px

          > div
            display: flex
            align-items: center
            justify-content: center

          .paymentPlanButtonsWrapper
            border: none

    .paymentImage
      width: 12vw

      @include breakpoint('lg')
        width: 16vw
