@import "../../utilities/globals.sass"
@import "../../../sources/mixins"

.customTextAreaWrapper
  position: relative
  margin-bottom: 1rem
  border: 1px solid #d7d7d7
  border-radius: 9px
  -webkit-box-shadow: 0 0 0 2px transparent
  -moz-box-shadow: 0 0 0 2px transparent
  box-shadow: 0 0 0 2px transparent
  transition: all 0.3s

  &:not(.danger)
    @include hover()
      border-color: $primary

  &:not(.danger):focus-within
    -webkit-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    -moz-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    border-color: $primary

  textarea
    border: none !important
    border-radius: 9px !important
    resize: none !important

  .errorMessage
    position: absolute
    left: 0
    top: 100%
    display: inline-block
    font-size: 0.875rem
    line-height: 1
    color: $negative
    opacity: 0
    transition: all 0.3s

    @include breakpoint('lg')
      font-size: 0.75rem

  .errorMessageVisible
    opacity: 1

.ghost
  border-color: transparent !important

  textarea
    padding: 0 !important
    opacity: 1 !important

.danger
  border-color: $negative

  &:focus-within
    -webkit-box-shadow: 0 0 0 2px rgba($negative,0.3)
    -moz-box-shadow: 0 0 0 2px rgba($negative,0.3)
    box-shadow: 0 0 0 2px rgba($negative,0.3)
