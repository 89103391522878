@import "../../../globals"
.asked-questions
  .title
    text-align: center
  p
    color: black
    text-align: justify
  .ask
    color: $primary!important
    margin-bottom: 0!important
  .footer
    margin-top: 2rem
    p
      text-align: center