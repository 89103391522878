@import "../globals"

.tutorial
  position: absolute
  background: $popupBackground
  right: 0
  left: 0
  bottom: 0
  top: 0
  z-index: 5
  .arrow.icon
    color: white
    font-size: 6em
    position: absolute
  .ui.segment
    height: max-content
    text-align: left
    h4
      font-size: 2em
  .text
    position: absolute
    text-align: right
    padding-right: 1rem
    padding-left: 1rem
    width: 100%
    .ui.button, .ui.button:active, .ui.button:hover, .ui.button:focus
      background: $secondary
      border-radius: 20px
      color: white
      border: 2px solid white
      font-size: 2em
      display: inline-block
    li
      margin: 15px 0
      font-size: 1.5em
    img
      position: relative
      top: 10px
  .ui.icon.button, .ui.icon.button:active, .ui.icon.button:hover, .ui.icon.button:focus
    position: absolute
    right: 0%
    top: 0%
    margin: 0
    font-size: 4em
    padding: 0
    background: transparent
    color: white
    padding: 0
  .dashboard
    .arrow.icon
      top: 15%
    .text
      top: 10%
  .quotes
    .arrow.icon
      top: 25%
    .text
      top: 10%
  .loan
    .arrow.icon
      top: 40%
    .text
      top: 10%
  .contact
    .arrow.icon
      top: 40%
    .text
      top: 35%
  .account
    .arrow.icon
      top: 40%
    .text
      top: 35%

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
  .dashboardContent
    .tutorial
      overflow-y: auto
      .ui.icon.button
        position: relative
        left: 90%
      .dashboard, .quotes, .loan, .contact, .account
        .text
          position: static
          margin-top: 6rem
        .ui.button
          margin: 5px 0
          min-width: 100%
        img
          position: static

// mobile vertical
@media only screen and (max-width: 768px)
  .tutorial
    overflow-y: auto
    .ui.icon.button
      position: relative
      left: 80%
    .dashboard, .quotes, .loan, .contact, .account
      .text
        position: static
      .ui.button
        margin: 5px 0
        min-width: 100%
      img
        position: static
// mobile horizontal
@media only screen and (min-width: 327px) and (orientation : landscape)
  .tutorial
