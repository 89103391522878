body
  margin: 0
  font-family: 'Poppins', sans-serif !important
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.ui, h1, h2, h3, h4, h5, span, p, label, a
  font-family: 'Poppins', sans-serif !important
  >div, >input
    font-family: 'Poppins', sans-serif !important

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

#root
  width: 100%
  height: 100%
