@import "../../../utilities/globals"

.footer
    padding: 1rem 0
    text-align: justify

    .color-dark
        color: $tertiary

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    .footer
        font-size: 14px

// mobile vertical
@media only screen and (max-width: 768px)
    .footer
        font-size: 11px