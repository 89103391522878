@import ./globals
.generalCarousel
  .carousel.carousel-slider
    margin: 0px auto
    overflow: visible
  .carousel
    .slide
      background: transparent
      .slide-holder
        display: flex
        flex-direction: row
        justify-content: center
        width: 100%
        height: 100%
  .control-arrow:before
      border-top: 15px solid transparent!important
      border-bottom: 15px solid transparent!important
  .control-prev
    padding-left: 0!important
    &:before
      border-right: 10px solid $secondary!important
  .control-next
    padding-right: 0!important
    &:before
      border-left: 10px solid $secondary!important
      
