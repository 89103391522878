@import "../../../utilities/globals"
@import "../../../../sources/mixins"

div.result-cars
    height: 100%
    width: 100%
    .items-grapper
        width: 100%
        background: transparent
    .tabs-payments
        width: 100%
        text-align: center
    .item-payment
        display: inline-block
        margin: 5px 10px
        width: 30%
        text-align: center
        .content-item
            width: 100%
            padding: 10px
            border: 1px solid $tertiary
            display: inline-block
            color: white!important
            background: $tertiary
            border-radius: 20px
            -webkit-box-shadow: -2px -1px 21px -7px rgba(0, 0, 0, 0.59)
            -moz-box-shadow: -2px -1px 21px -7px rgba(0, 0, 0, 0.59)
            box-shadow: -2px -1px 21px -7px rgba(0, 0, 0, 0.59)
            cursor: pointer
            .header
                color: white
            &:hover
                color: $tertiary
                background: white
                .header, span
                    color: $tertiary
        i
            font-size: 3em
            display: inline-flex
            align-items: center
            visibility: hidden
    .active
        .car-active
            color: $tertiary!important
            background: white!important
            .header, span
                color: $tertiary!important
        i
            visibility: visible
    .cars-grapper
        height: 70%
        overflow-y: auto
        padding: 0 1rem
        .cars
            width: 100%
            min-height: 10vh
            border: 2px solid $tertiary
            border-radius: 20px
            margin-bottom: 20px
            padding: 1rem

            button.btn-arrow,button.btn-arrow:active, button.btn-arrow:focus
                @include squareShape(6rem)
                padding: 0
                border-radius: 50%
                background: $tertiary
                color: white
                border: 1px solid $tertiary

                @include breakpoint('lg')
                    @include squareShape(4rem)

                &:hover
                    background: white
                    color: $tertiary
                i
                    width: auto
                    font-size: 40px
                    margin: 0!important
                    height: auto
                    position: relative
                    top: 4px
                    left: 2px


// mobile vertical
@media only screen and (max-width: 768px)
    div.result-cars
        .item-payment
            margin: 3px
            width: 30%
        .content-item
            font-size: 10px
            h3
                font-size: 14px
        .cars-grapper
            padding-top: 5px
            .cars
                h3
                    margin: 0 5px
                .btn-arrow-m
                    color: white
                    background: $tertiary
                    border: 1px solid $tertiary
                    border-radius: 20px
                    width: 100%
