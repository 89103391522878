@import "../../../../sources/mixins"
@import "../../globals"

.slidersWrapper
  div.segmentGrapper
    .header-slider
      min-height: 64px

      h3
        white-space: nowrap

        @include breakpoint('xl')
          font-size: 16px

        .customInputWrapper
          width: 30%

          @include breakpoint('xl')
            font-size: 16px
            min-width: 120px

          input
            padding: 8px 16px

            @include breakpoint('md')
              min-width: 140px

  div.sliderWrapper
    width: 100%
    height: 50px
    margin: 10px 0
    padding: 0 30px

    .rc-slider-dot
      background: $primary
      border-color: $primary
      bottom: -4px
      width: 10px
      height: 10px

    .rc-slider-rail
      background: $primary!important
      height: 6px

    .rc-slider-handle
      background: $secondary !important
      border-color: $secondary !important
      width: 16px
      height: 16px

    .rc-slider-dot-active
      background: white
      border-color: $secondary !important

    .rc-slider-track
      background: $secondary !important
      height: 6px

    .rc-slider-disabled
      background: none

    span
      cursor: auto!important

    .rc-slider-handle, span.rc-slider-dot
      cursor: auto!important

    .rc-slider-mark-text
      white-space: nowrap

.confirmButtonWrapper
  display: flex
  justify-content: center
  margin-bottom: 1rem
  width: 100%
