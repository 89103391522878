@import "../globals"
@import "../../../sources/mixins"

.messagePopoverWrapper
  position: relative
  display: flex
  align-items: center
  justify-content: center
  min-height: 28px
  min-width: 28px
  background-color: rgba($primary, 0.3)
  border: 1px solid $primary
  border-radius: 50%

  i
    position: relative
    top: -4px
    left: -2px
    margin: 0 !important
    width: 12px !important
    height: 12px !important
    color: $primary

  .messagesMark
    font-size: 12px
    font-weight: 600
    color: $primary

    @include breakpoint('xl')
      width: 16px
      height: 16px
      top: -10%
      right: -10%
      font-size: 10px

.visibleMessagesCount
  padding: 0 0.5rem
  justify-content: space-between
  width: 40px
  gap: 6px
  border-radius: 19px

.modals
  div.ui.modal
    .message-modal.content
      padding: 0!important
      .messages
        width: 100%
        border: 3px solid $primary
        border-radius: 4px
        .header
          width: 100%
          background: $primary
          color: white
          padding: 1rem
          display: flex
          align-items: center
          justify-content: space-between
          .ui.header
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden
            padding: 0
            margin: 0
          .ui.form
            padding: 0
            width: 90%
            label
              color: white!important
          .button
            background: $primary
            color: white
            padding: 0
            border: none
            font-size: 2rem
        .body
          height: 60vh
          overflow-y: auto
          overflow-x: hidden
          padding: 1em

        .action .textarea textarea
          resize: none !important

        .action .buttons
          gap: 0.5rem
          padding: 1rem 0
          margin: 0 1rem

        .action.ui.form
          .fields
            margin-bottom: 0!important

          .button
            width: 100%!important
            height: 100%!important

            i
              margin-left: 5px

        .message
          margin-bottom: 1rem
          .alert
            display: block
            width: 100%
            border-bottom: 1px solid $gray
            text-align: center
            margin: 1em 0
          .message-content
            display: flex
            .grapperBox
              width: 100%
              text-align: left
              display: inline-block
              font-size: 1rem
            .contentBoxSent
              width: 100%
              display: flex
              justify-content: flex-end
            .contentBoxReceived
              width: 100%
              display: flex
              justify-content: flex-start
            .text
              padding: 10px
              border-radius: 1em
              overflow: hidden
              text-overflow: ellipsis
              color: white
            .received
              background-color: $primary
            .sent
              background-color: $primary
            .iconsMsn
              padding-bottom: 5px
              // width: 5%
              display: flex
              align-items: end
              font-size: 1em
              justify-content: end
              color: $primary
              i
                margin: 0
          .date
            display: block
            font-size: 0.8rem
            color: $gray
            display: block

@media only screen and (max-width: 768px)
  .modals
    div.ui.modal
      .message-modal.content
        .messages
          .header
            .ui.form
              .six.wide.field
                width: 100%!important
