.sectionTitle
  position: relative
  margin-top: 0.25rem
  margin-bottom: 0.5rem
  font-size: 16px
  font-weight: 500

  &::after
    content: ""
    position: absolute
    right: 6px
    bottom: -2px
    display: block
    width: 100%
    height: 1px
    border-radius: 4px
    background-color: #d1d1d1
