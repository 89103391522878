@import "../globals"

.videos
  .section, .information
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
    border-radius: 4px
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    min-height: 40vh
  .section
    border: 1px solid $darkGray
    display: flex
    align-items: center
    height: 100%
    img
      width: 100%
  .information
    padding: 1rem
    .video
      width: 100%
      height: 100%
      border-radius: 4px
      overflow: hidden
      display: grid
      align-items: center
      justify-content: center
      grid-template-columns: repeat(1, 1fr)
      .item
        padding: 5px
        border: 1px solid $darkGray
        video
          width: 100%
      .clean-item
        height: 100%
        border: 1px solid $darkGray
        display: flex
        align-items: center
        justify-content: center
        i
          margin-top: 1rem
          color: $darkGray
          font-size: 3rem
  button.btn-update,  button.btn-update:active, button.btn-update:focus
    background: $primary
    color: white
    border-radius: 20px
    border: 1px solid $primary
    &:hover
        color: $primary
        background: white
        border: 1px solid $primary
  button.btn-remove
    border-radius: 20px
