@import "../globals"
@import "../../../sources/mixins"

.carComponent
    // width: 1200px
    max-width: 100%
    margin-left: auto
    margin-right: auto
    background: transparent

    .accordionWrapper
        border-radius: 9px !important
        overflow: hidden

        .accordion.ui
            .title
                flex: 1
                padding: 12px 20px
                border-radius: 9px !important
                background-color: $secondary !important
                font-size: 18px
                font-weight: 600
                color: #fff

                @include breakpoint('md')
                    font-size: 1.125rem

            #text
                display: none !important

    & > .grid
        display: flex
        justify-content: center
    h2.title
        font-weight: bold
        // font-size: 1.28em
        margin-bottom: 0
        display: flex
        justify-content: space-around
        color: $tertiary

        @include breakpoint('md')
            margin: 1rem 0 0.5rem !important

        @include breakpoint('sm')
            flex-direction: column-reverse
            margin-top: 0 !important

    h3.payment
        margin-top: 5px
        display: flex
        justify-content: center
        align-items: center
        color: white
    .prices
        display: flex
        justify-content: space-between
        margin: 15px 0
        color: $tertiary

        span
            text-align: start

    .monthlyPayment
        color: $darkGray!important
        margin: 12px 0 !important
        display: block
        text-align: center

        @include breakpoint('xs')
            flex-direction: column
            gap: 0.5rem

    .insurance-text
        display: flex
        justify-content: space-between
        margin: 0!important
        span
            color: #555555
            font-weight: normal
        i
            margin: 0
    .title-car
        color: $tertiary!important
        margin-bottom: 10px!important
    button.btn-detail, button.btn-detail:active, button.btn-detail:hover, button.btn-detail:focus
        background: $secondary
        border-radius: 20px
        color: white
    button.btn-detail-click
        color: $secondary!important
        background: white!important
        border: 1px solid $secondary!important
        i
            float: right
    .acceptButton
        width: 100%
        margin: 10px 0
    .terms
        color: $tertiary!important
    div.computer-prices
        span
            width: 100%
            display: block
            font-size: 1.28571429rem
    .prices-car
        text-align: center

        @include breakpoint('sm')
            margin-bottom: 0.5rem

        span
            width: 50%
            display: inline-block
            margin: 5px 0
            font-weight: bold

            @include breakpoint('sm')
                width: 100%

        .prices
            color: $darkGray!important
    .stk.ui-mobile
        display: none!important
    .stk.ui-computer
        text-align: center
        display: block!important
        font-size: 14px
        color: gray
        margin: 10px 0
    .border-t-b
        border-top: 1px solid $gray
        border-bottom: 1px solid $gray
        padding: 5px 0
    .infoIcon
        color: $primary
    .infoMIcon
        color: $darkGray

    .pointer
        position: relative
        display: flex
        justify-content: center
        align-items: center
        gap: 8px
        width: 260px
        height: 44px
        color: white
        border-radius: 9px
        background: rgb(24,0,115)
        background: linear-gradient(45deg, rgba(24,0,115,1) 0%, rgba(89,53,215,1) 100%)

        i
            color: #fff

    .chas-down
        display: block
        text-align: center
        color: $primary
        font-weight: bold

        @include breakpoint('md')
            margin-bottom: 0.5rem

    .rebates
        padding: 5px 1rem
        border-radius: 8px
        border: 1px solid $gray
        width: 100%
        .prices
            margin: 0

// tablet
// @media only screen and (min-width: 992px)

//tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    div.carComponent
        .columnGalery
            display: flex!important
            justify-content: center
        .column
            padding: 1rem!important
        h2.title, h3.payment
            text-align: left
        h3.payment
            margin-top: 5px
        .carDetails
            display: block
        .acceptButton
            text-align: left
            margin: 10px 0
        .btn-detail
            width: 50%
        .stk.ui-mobile
            display: none!important
        .stk.ui-computer
            text-align: center
            display: block!important
            font-size: 14px
            color: gray
            margin: 10px 0
        .pointer
            min-width: 90%

@media only screen and (max-width: 768px)
    .carComponent
        .columnGalery
            display: flex!important
            justify-content: center
        .column
            padding: 5px!important
        h2.title, h3.payment
            text-align: center
        h3.payment
            margin: 5px 0
        .carDetails
            display: none
        .acceptButton
            text-align: center
            margin: 5px 0
        .pointer
            min-width: 90%
        .grapper-methods
            margin-top: 2em
            padding: 5px
            .paymentHeader
                width: 100%
                padding: 0.5rem 1rem
                text-align: center
                color: white
                background: $primary
                border-radius: 6px 6px 0 0
            .paymentMobile
                padding: 1em
                text-align: center
                width: 100%
                background: white
                border-radius: 0 0 6px 6px
                color: $tertiary
                font-weight: bold
                border-bottom: 2px solid $primary
                border-left: 2px solid $primary
                border-right: 2px solid $primary
                span
                    display: block
                .left
                    width: 70%
                    display: inline-block
                    padding: 5px
                    font-size: 13px
                    label: white
                    .red
                        color: red !important
                    .green
                        color: #00D367 !important
                .right
                    width: 30%
                    padding: 5px
                    display: inline-block
                    span
                        cursor: pointer
                        background: $primary
                        color: white
                        border-radius: 10px
                        border: 1px solid $primary
                        padding: 5px 0
                        font-size: 12px
                        &:hover
                            color: $primary
                            background: white
                            border: 1px solid $primary
                        &:hover + .icons
                            // display: block
                    .icons
                        // display: none
                        img
                            height: 30px
                            width: 100%
                        i
                            display: block
                            width: 100%
                            text-align: center
                            font-size: 20px
                            margin: 0 0 5px 0
        .btn-detail
            width: 85%
        .stk.ui-computer
            display: none!important
        .stk.ui-mobile
            display: inline-block!important
            font-size: 12px
            color: gray
