@import '../../../sources/mixins'

.alertModal
    background: white!important

    @include breakpoint('xl')
        width: 340px !important

    .modalHeader
        margin: 1rem 1.5rem 0.5rem
        text-align: center

    .content
        background: none!important
        overflow-wrap: break-word
    .actions
        text-align: center!important
        background: none!important
        // border-top: 2px solid rgba(34,36,38,.15)!important
        border-top: 0 !important
