@import "../globals"
@import '../../../sources/mixins'

.trade-wrapper
  .tradeInColumn
    display: flex
    justify-content: space-between
    flex: 1

  .trande-in
    color: $primary
    text-align: end
    cursor: pointer
  .prices
    display: flex
    justify-content: space-between
    align-items: center
    margin: 15px 0
    color: $tertiary
    padding: 0.5rem 0

  .only-text
    margin: 0!important
    padding: 0 10px
.trade-modal
  .content
    background: white!important
    text-align: center

    .tradeModalInputWrapper
      display: flex !important
      flex-direction: column
      align-items: center

    .tradeModalInput
      width: fit-content

      input
        @include breakpoint('xs')
          width: 10rem

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button
        -webkit-appearance: none

      input[type=number]
        -moz-appearance: textfield

    .tradeModalFooter
      display: flex

      @include breakpoint('sm')
        flex-direction: column
        align-items: center
        gap: 0.5rem

      .imageContainer
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        min-width: 180px
        max-width: 320px
        height: auto
        margin: -1rem

      .description
        display: flex
        flex-direction: column
        justify-content: space-evenly
        gap: 0.5rem

        .button-green-outline
          background: white
          color: $positive
          border-radius: 9px
          border: 1px solid $positive
          padding: 0.5rem
          display: block
          transition: all 0.3s
          text-align: center

          &:hover
            background: $positive
            color: white

          @include breakpoint('md')
            h3
              font-size: 1rem

    .note
      padding-inline: 1rem

    .title-info
      color: $primary
    .or
      font-weight: bold
      font-size: 20px
    .logo
      display: inline-block
      width: 80%
    .button-black, .button-green
      padding: 1rem
      border-radius: 30px
      color: white

    .button-black
      margin: 15px 0
      background: black
      &:hover
        background: #4c4c4c

    .button-green
      background: $positive

      &:hover
        background: #10a559
  button.btn-detail, button.btn-detail:active, button.btn-detail:hover, button.btn-detail:focus
    background: $primary
    border-radius: 20px
    color: white

.updateButtonWrapper
  display: flex
  justify-content: center

.coverageProtectionButton
  justify-content: end !important

  > a
    padding-right: 0 !important
    background-color: #fff !important
    font-size: 16px
    font-weight: 400 !important
    color: $primary !important
    border: none !important

.valueWithImage
  display: flex
  align-items: center
  gap: 20px

  > img
    width: 140px

    @include breakpoint('xl')
      width: 100px

    @include breakpoint('lg')
      width: 80px

.recordWrapper
  display: flex
  justify-content: space-between
  align-items: center

.alignEnd
  justify-content: end
  margin-bottom: 12px

.alignedPrices
  display: flex
  justify-content: space-between
  align-items: center

.marginBottom
  margin-bottom: 12px

.sectionsDivider
  margin: 12px 0
  width: 100%
  height: 1px
  background-color: #e6e6e6
