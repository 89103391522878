@import "../../../../utilities/globals"
@import "../../../../../sources/mixins"

.slidersFlowButton
  margin: 0 0 0.5rem !important

  h3
    font-size: 16px

    @include breakpoint('lg')
      margin-bottom: 0.25rem
      font-size: 12px
      font-weight: 500

  div.subtext
    font-size: 14px
    font-weight: 400

    @include breakpoint('lg')
      font-size: 9px

.buttonWrapper
  margin: 0 0 0.5rem !important

  @include breakpoint('xl')
    padding-inline: 0.75rem !important

    .grid
      flex-direction: column !important
      align-items: center !important

    .column
      flex-direction: column

    .tradeMark
      white-space: nowrap

    p
      text-align: center

    span.capture
      display: inline-block
      font-size: 0.875rem
      text-align: center

      @include breakpoint('lg')
        font-size: 9px
        font-weight: 400
        line-height: 1.5
