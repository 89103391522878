@import  "../globals"

.carouselImageList
  .carousel
    .slide
      // min-height: 21vh
      background: transparent
    .thumbs-wrapper
      margin: 0!important
    .control-next.control-arrow
      right: 5px!important
      &::before
        border: none
        content: '>'
    .control-prev.control-arrow
      left: 5px!important
      &::before
        border: none
        content: '<'
    .control-arrow
      top: 50%!important
      bottom: inherit!important
      border-radius: 50%!important
      background: $popupBackground!important
      opacity: 1!important
      padding: 0px 5px 5px 7px !important
    .thumbs
      margin: 0!important
      padding: 0!important
    .thumbs-wrapper
      .control-arrow
        padding: 1px 5px 5px 1px !important
        color: white
    .grapper-video
      display: flex
      height: 100%

.carouselImageContainer
  margin-top: auto
  margin-bottom: auto

.image-gallery
  min-height: 40vh
  .image-gallery-image
    min-height: 20vh
  .image-gallery-right-nav
    right: 10px
  .image-gallery-left-nav
    left: 10px
  .image-gallery-right-nav, .image-gallery-left-nav
    padding: 0px
    border-radius: 50%
    background: $popupBackground
  .image-gallery-svg
    width: 35px
    height: 35px

.slide
  height: fit-content

  .img-video
    display: none
// tablet
@media only screen and (min-width: 992px)
  div.container

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
  div.image-gallery
    .image-gallery-slide img
      min-height: 17vh
    div.image-gallery-thumbnails-wrapper
      display: block
  div.carouselImageList
    width: 100%
    .carousel
      .thumbs-wrapper
        display: block

@media only screen and (max-width: 768px)
  .image-gallery
    width: 85%
    height: auto
    // min-height: 30vh
    min-height: inherit
    .image-gallery-slide img
      width: 100%
      height: auto
      min-height: 30vh
    .image-gallery-svg
      width: 25px
      height: 25px
  div.image-gallery-thumbnails-wrapper
    display: none
  .carouselImageList
    width: 90%
    text-align: center
    .carousel
      .thumbs-wrapper
        display: none
