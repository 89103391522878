@import "../globals"

.quote-table
  width: 100%
  height: 55vh
  overflow-y: auto
  overflow-x: auto
  background: white!important
  thead
    background: white!important
    th
      background: white!important
      padding-bottom: 1rem!important
  .ui.table thead tr:first-child > th
    position: sticky !important
    top: -1px
    z-index: 2
  .loanStatus
    width: 100%
    pointer-events: none
.approval-box
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
  border-radius: 4px
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
  border-top: 1px solid $gray
  padding-top: 1rem
  padding-bottom: 1rem
  margin: 2.5rem 1rem 0rem 1rem
.d-none
  display: none

.quote-approval
  .field
    margin-bottom: 1em!important
    .field
      margin-bottom: 0!important
  .final-step
    padding: 4rem
    text-align: center
  .padding-top
    padding-top: 1rem
  .disabled
    opacity: 1!important
  .field.disabled, .disabled
    label
      opacity: 1!important
    input[type="text"]:disabled, input[type="email"]:disabled, .selection, .dropdown
      outline: none
      padding: 0 0 5px 0
      box-shadow: none!important
      opacity: 1
      padding-left: 0!important
      padding-right: 0!important
      background: none!important
      border: none!important
      &::placeholder
        color: transparent
      .dropdown
        display: none

// table vertical
@media only screen and (max-width: 1024px)
  .quote-table
    height: 55vh

@media only screen and (max-width: 768px)
  .quote-table
    height: auto
  .quote-approval
    .final-step
      padding: 2rem
  
// mobile horizontal
@media only screen and (min-width: 327px) and (max-width: 768px) and (orientation : landscape)
  .quote-table
    height: auto