@import "../globals"
.bankAccount
  div.box
    height: 100%
    // height: 40vh
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
    border-radius: 4px
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    border-top: 1px solid $gray
    padding-top: 1rem
    padding-bottom: 1rem
    .verify-msn
      padding: 0px 0 5px 0
      span
        color: $primary
  .button-loading
    position: absolute!important
    background: $popupBackground!important