@import "../../../../../../sources/mixins"

.swiperButton
  position: absolute
  top: calc(50% - 10px)
  z-index: 3

  > button
    width: 44px
    height: 44px
    background-color: #fff !important
    border: none !important

    @include breakpoint('sm')
      padding: 8px 12px !important
      width: 36px
      height: 36px

.prevButton
  left: 0

.nextButton
  right: 0

.arrow
  width: 16px
  height: 16px

  @include breakpoint('sm')
    width: 12px
    height: 12px

.rotated
  transform: rotate(180deg)
