@import "../globals"

.customModal
  .modalTitle
    margin-top: 40px
    text-align: center
    color: $tertiary
    font-size: 2.7rem
  .modalContent
    margin-top: 0 !important
    margin-bottom: 0 !important
    padding: 20px
.customButton
  padding: 0 !important
  // margin-top: 20px
  button
    border-radius: 18px !important
    font-size: 1.2rem !important
    padding: 17.5px 35px !important
    font-weight: 500 !important

.cancelButton
  background-color: $tertiary
