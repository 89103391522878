@import "../../utilities/globals.sass"
@import "../../../sources/mixins"

.customDropdownWrapper
  position: relative
  margin-bottom: 1rem
  border: 1px solid #d7d7d7
  border-radius: 9px
  transition: all 0.3s !important
  -webkit-box-shadow: 0 0 0 2px transparent
  -moz-box-shadow: 0 0 0 2px transparent
  box-shadow: 0 0 0 2px transparent

  &:not(.danger)
    @include hover()
      border-color: $primary

  &:not(.danger):focus-within
    -webkit-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    -moz-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    border-color: $primary

  .ui.dropdown .default.text
    color: #000 !important

  .ui.selection.dropdown .menu
    border-top-width: 1px !important

  .ui.active.selection.dropdown
    border-radius: 9px !important

  .dropdown:not(.icon)
    width: 100%
    border: none !important
    border-radius: 9px !important
    min-width: 6rem !important

  div.menu
    position: absolute
    top: 46px !important
    padding: 0.125rem
    width: 100%
    max-height: 12rem
    border: 1px solid $primary !important
    border-radius: 9px !important
    z-index: 10
    background-color: #fff
    overflow-y: auto
    -webkit-box-shadow: 0 0 0 2px rgba(65,174,255,0.3) !important
    -moz-box-shadow: 0 0 0 2px rgba(65,174,255,0.3) !important
    box-shadow: 0 0 0 2px rgba(65,174,255,0.3) !important

    &::-webkit-scrollbar-track
      border-radius: 0 12px 12px 0
      background-color: #fff

    div.item
      padding: 0.5rem 1rem
      border-top: 0 !important
      transition: color 0.3s

      > .text
        transition: all 0.3s

      @include hover()
        background-color: #fff
        cursor: pointer

        > .text
          color: $primary !important

  div.selected.item
    border-radius: 7px
    background-color: rgba($primary, 0.2)
    font-weight: 500

  .errorMessage
    position: absolute
    left: 0
    top: 2.85rem
    display: inline-block
    font-size: 0.875rem
    line-height: 1
    color: $negative
    opacity: 0
    transition: all 0.3s

    @include breakpoint('lg')
      font-size: 0.75rem

  .errorMessageVisible
    opacity: 1

.placeholderAppearance
  .ui.dropdown .default.text
    color: rgba(191,191,191, 0.87) !important

.emptyResult
  display: flex
  flex-direction: column
  align-items: center
  gap: 0.875rem
  padding: 0.5rem

  > img
    width: 40px
    height: 40px

  > span
    font-weight: 500
    color: #c1c1c1

.ghost
  border-color: transparent !important

  .dropdown
    min-height: 38px
    background-color: transparent !important

    div.divider.text
      position: relative
      top: 4px !important

  i
    display: none !important

  .ui.selection.dropdown
    padding: 0 !important

.danger
  border-color: $negative

  &:focus-within
    -webkit-box-shadow: 0 0 0 2px rgba($negative,0.3)
    -moz-box-shadow: 0 0 0 2px rgba($negative,0.3)
    box-shadow: 0 0 0 2px rgba($negative,0.3)
