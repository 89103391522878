@import "../globals"

.loanDetails
  .field
    margin-bottom: 1em!important
    .field
      margin-bottom: 0!important
  .activated
    color: $positive!important
  .stoped
    color: red!important
  .disabled
    opacity: 1!important
  .field.disabled, .disabled
    label
      opacity: 1!important
    .calendar.icon
      display: none
    input[type="text"]:disabled, input[type="email"]:disabled, .selection
      outline: none
      padding: 0 0 5px 0
      box-shadow: none!important
      opacity: 1
      padding-left: 0!important
      padding-right: 0!important
      border: none
      background: none!important
      .dropdown
        display: none
    ::-webkit-input-placeholder
      color: transparent
    :-moz-placeholder
      color: transparent
    ::-moz-placeholder
      color: transparent
    :-ms-input-placeholder
      color: transparent
    input::placeholder
      color: transparent

.payment-schedule
  .nextPayment
    color: white
    background: $primary
  .cancelledPayment
    color: white
    background: red
  .finalPayment
    color: white
    background: $positive
