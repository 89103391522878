@import '../utilities/globals'
@import '../../sources/mixins'

.dashboard
  .detailsQuote
    cursor: pointer
    color: $primary

  .dashboardContent
    .body
      .generalInfo
        padding: 0 !important

        @include breakpoint('xl')
          .customStatistic
            width: 120px

        @include breakpoint('sm')
          .customStatistic
            width: 150px

      .statisticWrapper
        display: flex
        gap: 12px
        width: 100%

        @include breakpoint('sm')
          flex-direction: column

        .totalDealsCard
          .imageContainer
            &::after
              background-color: #fee9b8

            > svg
              fill: #f3c464

        .buyersCard
          .imageContainer
            &::after
              background-color: #95a3e9

            > svg
              fill: #556ee6

        .calendarCard
          .imageContainer
            &::after
              background-color: #eb8686

            > svg
              fill: #e66868

      .scaled
        &:hover
          transform: scale(1.1)
          box-shadow: 1px 1px 5px transparent

      table
        a
          color: $secondary

      .sectionHeader
        margin-bottom: 16px
        font-size: 24px
        font-weight: 700
        margin-top: 0

      .infoList
        .sectionWrapper
          height: 100%

        ul
          display: flex
          flex-direction: column
          gap: 8px
          padding: 0
          list-style: none
          margin: 0

        .retailerInfoWrapper
          display: flex
          justify-content: space-between
          align-items: center

          > span
            font-weight: 500

            &:nth-child(1)
              font-size: 18px

            &:nth-child(2)
              font-size: 16px

      .chartDates
        margin: 10px 0
        display: flex
        justify-content: center
        align-items: center
        gap: 20px

        @include breakpoint('sm')
          justify-content: center
          margin: 10px 0
          gap: 12px

        .dateWrapper
          @include breakpoint('xs')
            label
              display: inline-block
              width: 100%
              text-align: center

            .react-datepicker-wrapper
              width: 100%
              text-align: center

              .customDatePicker
                justify-content: center
                width: 100%

      .graphEmpty
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 8px
        height: 400px

        @include breakpoint('xxl')
          height: 340px

        @include breakpoint('xxl')
          height: 260px

        @include breakpoint('lg')
          height: 200px

        > svg
          @include squareShape(6rem)
          fill: #ababab

          @include breakpoint('xxl')
            @include squareShape(4rem)

          @include breakpoint('lg')
            @include squareShape(3rem)

        > h3
          font-size: 22px
          color: #ababab
          text-align: center

          @include breakpoint('xxl')
            font-size: 20px

          @include breakpoint('xl')
            font-weight: 500

          @include breakpoint('lg')
            margin-top: 0.5rem
            font-size: 16px

      .cardExtraContent
        display: flex
        flex-direction: column

        .customButtonWrapper:not(:last-child)
          margin-bottom: 12px

      button.retailerButton, button.retailerButton:active, button.retailerButton:focus
        background-color: $primary
        color: white
        border: 1px solid $primary
        border-radius: 20px
        margin: 5px 0
        width: 100%
        &:hover
          background-color: white
          color: $primary
      button.variablesButton, button.variablesButton:active, button.variablesButton:focus
        background-color: $secondary
        color: white
        border: 1px solid $secondary
        border-radius: 20px
        margin: 5px 0
        width: 100%
        &:hover
          background-color: white
          color: $secondary
      button.creditButton, button.creditButton:active, button.creditButton:focus
        background-color: $primary
        color: white
        border: 1px solid $primary
        border-radius: 20px
        margin: 5px 0
        width: 100%
        &:hover
          background-color: white
          color: $primary

.hoverableCard
  transition: all 0.3s

  @include hover()
    transform: scale(1.05)
    cursor: pointer
