@import  "../globals"
@import "../../../sources/mixins"

.grapper-datails-background
    // background-image: url("../../../images/background-chromacars.png")
    background: white

.grapper-datails
    height: 100%
    overflow-y: auto
    overflow-x: hidden
    // padding: 1rem

    .actions
        display: flex !important
        align-items: start
        flex: 1
        padding: 0 !important

        @include breakpoint('xl')
            flex-direction: column-reverse
            gap: 1rem

        > div
            width: 50%

            @include breakpoint('xl')
                width: 100%

            &:first-child
                display: flex
                align-items: center
                flex-direction: column-reverse
                gap: 1.75rem

                @include breakpoint('xl')
                    flex-direction: row
                    justify-content: space-evenly
                    align-items: center

                @include breakpoint('sm')
                    flex-direction: column

    .edit-able
        div.column.colum-slider
            padding: 0 !important

        .sliderWrapper .container-slider
            display: flex
            flex-direction: column
            justify-content: space-between
            gap: 1rem
            width: 100%
            height: 100%

    .weeklyContainer
        display: flex
        justify-content: center
        margin-bottom: 1rem

    .a2uWeekly
        display: flex
        justify-content: center
        align-items: center
        gap: 8px
        width: 260px
        height: 44px
        color: #fff !important
        border-radius: 9px !important
        background: rgb(24,0,115)
        background: linear-gradient(45deg, rgba(24,0,115,1) 0%, rgba(89,53,215,1) 100%)

        i
            font-size: 1em!important
            position: relative
            margin: 0 !important
            color: #fff

    .bottom-padding .column
        padding-bottom: 0!important
    .back-dashboard
        width: 100%
        text-align: center
        color: $primary
        padding: 1.5rem
        cursor: pointer
        h2
            display: inline
    .insurance-protection
        margin-bottom: 10px

        .field
            display: inline-block
        i
            display: inline-block
            color: $tertiary
    .iconPdf
        width: fit-content
        cursor: pointer
        font-weight: bold
        i
            font-size: 30px
            color: $tertiary
    .price-custom
        .prices
            span
                width: 49%
                display: inline-block!important
                padding-right: 10px
                text-align: right
            .value
                text-align: left!important
                padding-left: 10px

    .no-BoxShadow
        .container-slider
            div.segmentGrapper
                box-shadow: inherit
    .paymentHeader
        width: 100%
        padding: 0.5rem 1rem
        text-align: center
        color: white
        background: $primary
        border-radius: 6px 6px 0 0
    .grapperPayments
        display: flex
        justify-content: center
        margin: 2rem 0 0 0!important
        color: $tertiary
    .border-bottom
        border-bottom: 1px solid $tertiary
    img.icon-payments
        width: 12vw!important
    .title-payments
        color: $tertiary!important
        i
            display: inline-block!important
            margin: 0 0 0 2px
            font-size: 20px!important
    .color-blue-ligth
        color: $primary

    .borderFinal
        // height: 100%
        border: 1px solid $primary
        border-radius: 8px
    .ui-mobile
        .borderFinal
            border-radius: 0 0 8px 8px
            border-bottom: 1px solid $primary
            border-left: 1px solid $primary
            border-right: 1px solid $primary
    .quote-status
        margin: 10px 0
        span
            color: $tertiary
    button.btn-cancel-quote,  button.btn-cancel-quote:active, button.btn-cancel-quote:focus
        background: red
        color: white
        border-radius: 20px
        border: 1px solid red
        &:hover
            color: red
            background: white
            border: 1px solid red
    button.btn-accept-quote,  button.btn-accept-quote:active, button.btn-accept-quote:focus
        background: $secondary
        color: white
        border-radius: 20px
        border: 1px solid $secondary
        &:hover
            color: $secondary
            background: white
            border: 1px solid $secondary
    button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
        background: $primary
        color: white
        border-radius: 20px
        border: 1px solid $primary
        margin-bottom: 5px
        &:hover
            color: $primary
            background: white
            border: 1px solid $primary
    button.btn-option,  button.btn-option:active, button.btn-option:focus
        background: $primary
        color: white
        border-radius: 20px
        border: 1px solid $primary
        width: 95%
        margin: 10px 5px 0 5px
        &:hover
            background: $tertiary
            color: white
            border: 1px solid $tertiary
    button.btn-payment,  button.btn-payment:active, button.btn-payment:focus
        background: $tertiary
        color: white
        border-radius: 20px
        border: 1px solid $tertiary
        width: 95%
        margin: 10px 0 0 0
        &:hover
            background: $tertiary
            color: white
    button.btn-cancel,  button.btn-cancel:active, button.btn-cancel:focus
        background: $secondary
        color: white
        border-radius: 20px
        border: 1px solid $secondary
        margin: 10px 5px 10px 5px
        &:hover
            background: white
            color: $secondary

    .buyerInformationButtonWrapper
        display: flex
        justify-content: center

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    div.grapper-datails
        img.icon-payments
            width: 25vw!important
// mobile vertical
@media only screen and (max-width: 768px)
    .grapper-datails
        padding: 0
        img.icon-payments
            width: 40vw!important
        div.edit-able
            padding: 0
            width: 100%

// mobile horizontal
@media only screen and (min-width: 327px) and (max-width: 768px) and (orientation : landscape)
    .grapper-datails
        padding: 0
        div.edit-able
            padding: 0
            width: 100%
