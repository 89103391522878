@import "../../../utilities/globals"

.similarCars
    h2.title-search
        text-align: center
        color: $tertiary
        font-weight: normal
    #detailPayments
        border: 2px solid $tertiary
        border-radius: 8px
    .monthly-pay
        color: $darkGray
        .infoMIcon
            color: $darkGray
    .infoIcon
        color: $primary
    .btn-collapse
        width: 100%
        display: flex
        justify-content: center
        margin-bottom: 1rem
    .car-search
        background: transparent
        h2.btn-search
            background: $primary
            padding: 5px
            text-align: center
            margin: 0
            color: white
            border-radius: 6px
            // cursor: pointer
            border: 1px solid $primary
            // &:hover, &:active, &:focus
            //     color: $primary
            //     background: white
            //     border: 1px solid $primary
        .weekly-pay
            text-align: center
            display: block
            color: $primary
            margin: 5px 0
        .monthly-pay
            text-align: center
            margin: 5px
        .weekly-months
            text-align: center
            color: $primary
            display: block
        .bottom
            margin-bottom: 10px
    button.btn-det
        padding: 1rem
        &:focus
            background: $tertiary
            color: white
    button.btn-det
        width: 97%
        background: $tertiary
        color: white
        border-radius: 20px 
        border: 1px solid $tertiary
        &:hover
            background: white
            color: $tertiary
            border: 1px solid $tertiary
    button.btn-det-click, button.btn-det-click:active, button.btn-det-click:focus
        background: white
        color: $tertiary!important
        border: 1px solid $tertiary!important
        i
            float: right
    .no-results
        padding: 10px 5px
        border: 1px solid $tertiary
        border-radius: 5px
        // border-top: 1px solid $tertiary
        // border-bottom: 1px solid $tertiary
        text-align: center
    .content-car
        .row-detail
            width: 100%
            text-align: center
            font-size: 30px
            padding: 10px 0
            color: $tertiary

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    .similarCars
        .content-car
            padding: 1rem!important

// mobile vertical
@media only screen and (max-width: 768px)
    .similarCars
        .content-car
            padding: 0 10px!important
        div.mobileCar
            width: 90%
            border-radius: 6px
            overflow: hidden
            margin: 5px 0!important
            text-align: center
            img
                width: 100%
            .title
                background: $primary
                padding: 5px
                color: white
                padding: 5px!important
                display: flex!important
                justify-content: center
                align-items: center
                font-weight: bold
            .car
                padding: 5px 1rem!important
                background: white
                color: $tertiary
                border: 1px solid $tertiary
                border-radius: 0px 6px 6px 0px
                p
                    margin: 5px 0
                    font-size: 11px
                label
                    font-weight: bold
                    display: block
                    color: $tertiary!important
