@import "../../../utilities/globals"

.video-chroma
    text-align: center
    padding: 10px 0
    .brand
        color: $primary
    video, iframe
        height: 30vh
        width: 40vw
    .full-video
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: $popupBackground
        z-index: 50
        text-align: center
        display: grid
        .close
            font-size: 2.5rem
            display: flex
            align-items: center
            justify-content: end
            color: white
            margin-right: 1rem
        .grapper-close
            display: flex
            justify-content: end
            padding-right: 1rem          
            align-items: center
        .grapper-video
            video
                width: 100%
                height: 100%
    h2.btn-video
        color: $primary
        cursor: pointer

//tablet horizontal
@media only screen and (min-width: 991px)
    .video-chroma
        text-align: center
        padding: 10px 0
        video, iframe
            height: 30vh
            width: 40vw

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    .video-chroma
        text-align: center
        padding: 10px 0
        video, iframe
            width: 40%

// mobile vertical
@media only screen and (max-width: 768px)
    .video-chroma
        text-align: center
        video, iframe
            width: 95%
        .full-video
            .grapper-video
                video
                    width: 100%
                    height: auto
// mobile horizontal
@media only screen and (min-width: 327px) and (max-width: 991px) and (orientation : landscape)
    .video-chroma
        .full-video
            .grapper-video
                video
                    width: 100%
                    height: 75%
