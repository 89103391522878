@import "../../../utilities/globals"
@import "../../../../sources/mixins"

.payment
    margin: 0.5rem 0

    span
        font-size: 1.5rem

        @include breakpoint('xl')
            font-size: 1.25rem

        small
            font-size: 1rem

.paymentOptions
    div.column:not(.headerColumn)

        @include breakpoint('xl')
            padding: 0.5rem 1rem !important

.grapperPayments
    display: flex
    justify-content: center
    margin: 2rem 0!important
    color: $tertiary

    .border-bottom
        border-bottom: 1px solid $tertiary

    h3.title-payments
        color: $tertiary

        .icon-payments
            width: 12vw

        i.info
            display: inline-block
            margin: 0 0 0 2px
            font-size: 20px

.dobleLine
    text-decoration-line: underline
    text-decoration-style: double
    text-decoration-color: $primary
.paymentsBorder
    height: 100%
    padding: 0!important
    border: 1px solid $primary
    margin: 0!important

    > div:not(.staticInformation)
        > span
            display: inline-block
            height: 20px

            @include breakpoint('xl')
                font-size: 12px

        .lastRecord
            height: 40px

    .paymentAction
        height: 100px
        padding: 0 !important

.paymentsBorder, .paymentsBorderMobile, div.mobileCar
    .advantageGreen
        color: black
        margin-right: 15px
        position: relative
        span
            font-size: 7px
            position: absolute
            top: -3px
            height: max-content

    .btn-payment
        .ui.button
            h4
                margin: 0

    .btn-payment-default
        .ui.button
            background: white
            border: 1px solid $primary
            width: 100%

            h4
                margin: 0
                color: $primary

            img
                width: 50px
                margin-right: 5px

                @include breakpoint('lg')
                    width: 36px !important

            .img-2
                display: none

            i
                color: $primary

            p
                margin: 0

                @include breakpoint('lg')
                    font-size: 12px
                    font-weight: 500

            &:hover, &:active, &:focus
                background: $primary
                border-color: $primary
                
                h4
                    color: white

                i
                    color: white
                .img-2
                    display: block
                .img-1
                    display: none

    .btn-payment-green
        .ui.button
            background: $positive
            border: 1px solid transparent
            width: 100%
            color: white

            @include breakpoint('md')
                padding: 0.2rem !important
            p
                margin: 0

            h3
                span
                    @include breakpoint('lg')
                        font-size: 12px
                        white-space: pre
                        font-weight: 500

            &:hover, &:active, &:focus
                border: 1px solid $positive
                background: white
                color: $positive

.nomargin
    margin: 0

    @include breakpoint('lg')
        font-size: 12px

.paymentOptions, .grapperPayments, div.mobileCar
    .btn-payment
        .ui.button
            cursor: default!important

            width: 100%
            background: $tertiary
            color: white

            border: 1px solid $tertiary

            img
                width: 50px
                margin-right: 5px

                @include breakpoint('lg')
                    width: 36px !important

            .img-2
                display: none

            &:hover, &:active, &:focus
                background: white
                color: $tertiary
                border: 1px solid $tertiary

                .img-2
                    display: block

                .img-1
                    display: none

                span
                    cursor: pointer
                    display: inline-block

    button.btn-payment, button.btn-payment-static, button.btn-payment-mobile
        width: 100%
        background: $tertiary
        color: white
        border: 1px solid $tertiary

        img
            width: 50px
            margin-right: 5px

            @include breakpoint('lg')
                width: 36px !important
        .img-2
            display: none

        &:hover, &:active, &:focus
            background: white
            color: $tertiary
            border: 1px solid $tertiary

            .img-2
                display: block

            .img-1
                display: none

    button.btn-payment-mobile
        margin-bottom: 5px
        margin-top: 5px
        padding: 10px
        span
            display: inline-block!important
    button.btn-payment-static
        border: 1px solid $tertiary
        cursor: default
        &:hover, &:active, &:focus
            background: $tertiary
            color: white
    button.btn-text
        background: $primary
        border-radius: 0
        color: white
        width: 100%
        margin-bottom: 15px
        position: relative
        cursor: default!important
        padding: 1rem 5px
        span
            cursor: pointer
        &:hover, &:active, &:focus
            background: $primary
            color: white
        &::after
            content: ''
            display: block
            position: absolute
            left: 50%
            top: 100%
            width: 0
            height: 0
            border-bottom: 10px solid transparent
            border-top: 10px solid #3EBFE9
            border-left: 10px solid transparent
            border-right: 10px solid transparent

.highlighted
    color: $primary

.red
    color: $negative

.green
    color: $positive

// tablet vertical
@media only screen and (max-width: 991px) and (min-width: 768px)
    .grapperPayments
        div.column
            padding: 1rem

// mobile vertical
@media only screen and (max-width: 768px)
    .grapperPayments
        div.column
            padding: 5px
