@import "../globals"
@import "../../../sources/mixins"

.customModal
    background: white!important

    .headerWrapper
        display: flex
        align-items: center
        gap: 12px
        margin-bottom: 1rem

        > h3
            margin: 0

        > svg
            width: 24px
            height: 24px

    .modalTitle
        margin-top: 40px
        text-align: center
        color: $tertiary
        font-size: 2.7rem
    .modalContent
        margin-bottom: 20px
        padding: 0 !important

        .logo
            height: 300px
            width: auto
        .required-inut
            color: red
        input
            box-shadow: 0 0 8px -3px #666
        input[type="file"]
            overflow-x: hidden
        label
            color: $tertiary!important
            font-weight: 700!important
            width: 100%
            display: block
        ::-webkit-input-placeholder
            color: transparent
        :-moz-placeholder
            color: transparent
        ::-moz-placeholder
            color: transparent
        :-ms-input-placeholder
            color: transparent
        input::placeholder
            color: transparent
        .customButton
            padding: 0 !important
            // margin-top: 20px

        .disabled
            opacity: 1!important
        .field.disabled, .disabled
            label
                opacity: 1!important
            input[type="text"]:disabled, input[type="email"]:disabled, .selection
                outline: none
                padding: 0 0 5px 0
                box-shadow: none!important
                opacity: 1
                padding-left: 0!important
                padding-right: 0!important
                border: none!important
                background: none!important

                .dropdown
                    display: none

                    button
                        border-radius: 18px !important
                        font-size: 1.2rem !important
                        padding: 17.5px 35px !important
                        font-weight: 500 !important

        .fields .field:not(.addressField)
            @include breakpoint('md')
                margin-bottom: 1rem !important

.acceptButton, button.acceptButton:active, button.acceptButton:focus
    background-color: $primary !important
    color: white !important
    border: 1px solid $primary !important
    min-width: 150px

    @include breakpoint('md')
        margin-bottom: 0 !important

    &:hover
        background-color: white !important
        color: $primary !important

.editButton, button.editButton:active, button.editButton:focus
    background-color: $primary !important
    color: white !important
    border: 1px solid $primary !important
    min-width: 150px

    @include breakpoint('md')
        margin-bottom: 0 !important

    &:hover
        background-color: white !important
        color: $primary !important

.cancelButton, button.cancelButton:active, button.cancelButton:focus
    background-color: red !important
    color: white !important
    border: 1px solid red !important
    min-width: 150px

    @include breakpoint('md')
        margin-bottom: 0 !important

    &:hover
        background-color: white !important
        color: red !important

.closeButton, button.closeButton:active, button.closeButton:focus
    background-color: $secondary !important
    color: white !important
    border: 1px solid $secondary !important
    min-width: 150px

    @include breakpoint('md')
        margin-bottom: 0 !important

    &:hover
        color: $secondary !important
        background-color: white !important

.actionButton
    @include breakpoint('md')
        min-width: 100px !important
        font-size: 1rem !important

    @include breakpoint('xs')
        min-width: 80px !important
        width: 80px !important

.upperSection
    @include breakpoint('md')
        flex-direction: column-reverse !important

.contactFormWrapper
    display: flex
    gap: 12px

    @include breakpoint('md')
        flex-direction: column-reverse

    > div
        width: 50%

        @include breakpoint('md')
            width: 100%

.editImageWrapper
    display: flex
    flex-direction: column !important
    justify-content: space-between
    margin-bottom: 0 !important

    img
        height: 180px !important
        object-fit: contain

        @include breakpoint('md')
            height: 140px !important

    input
        padding: 6.5px 14px !important


.locationWrapper
    .addressField
        margin-bottom: 1rem !important

.ui.form .fields.imageContainer
    flex-direction: column
    margin-bottom: 1rem !important

    @include breakpoint('md')
        div.field
            margin-bottom: 0 !important

    img
        width: fit-content

        @include breakpoint('md')
            width: 100%

.customForm
    display: flex
    flex-direction: column
    gap: 16px

    .sectionWrapper
        border: 1px solid #d7d7d7
        background-color: #fff

    .fields
        @include breakpoint('lg')
            flex-wrap: wrap

// mobile vertical
@media only screen and (max-width: 768px)
    .customModal
        .modalContent
            .logo
                width: 100%
                height: auto
// mobile horizontal
@media only screen and (min-width: 327px) and (max-width: 768px) and (orientation : landscape)
    .customModal
        .modalContent
            .logo
                width: 50%
                height: auto
