@import  "../../utilities/globals"
.contactForm
  .customButton
    padding: 0 !important
    margin-top: 20px
    button
      border-radius: 18px !important
      font-size: 1.2rem !important
      padding: 17.5px 35px !important
      font-weight: 500 !important
  .required-inut
    color: red
  .disabled
    opacity: 1!important
  .field.disabled, .disabled
    label
      opacity: 1!important
    input[type="text"]:disabled, input[type="email"]:disabled, .selection
      outline: none
      padding: 0 0 5px 0
      box-shadow: none!important
      opacity: 1
      padding-left: 0!important
      padding-right: 0!important
      background: none!important
      border: none!important
      .dropdown
        display: none
    ::-webkit-input-placeholder
      color: transparent
    :-moz-placeholder
      color: transparent
    ::-moz-placeholder
      color: transparent
    :-ms-input-placeholder
      color: transparent
    input::placeholder
      color: transparent
  button.btn-continue,  button.btn-continue:active, button.btn-continue:focus
    background: $primary
    color: white
    border-radius: 20px
    border: 1px solid $primary
    &:hover
        color: $primary
        background: white
        border: 1px solid $primary


// mobile vertical
@media only screen and (max-width: 768px)
  form.contactForm, div.contactForm
    .fields
      margin-bottom: 0!important
    .field
      margin-bottom: 1rem!important
      .field
        margin-bottom: 0rem!important
