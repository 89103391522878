@import "../../../../sources/mixins"

.cardWrapper
  display: flex
  align-items: center
  justify-content: space-between
  gap: 12px
  padding: 1rem 2rem 1rem 1.5rem
  max-width: 260px
  width: 100%
  border-radius: 5px
  background-color: #fff
  transition: all easy-out 0.4s
  overflow: hidden

  @include breakpoint('xxl')
    padding: 0.5rem 1.5rem 0.5rem 1rem

  @include breakpoint('sm')
    max-width: 100%

  .infoTab
    display: flex
    flex-direction: column
    justify-content: space-around
    height: 100px
    padding: 1rem 0
    z-index: 1

    @include breakpoint('xxl')
      justify-content: space-between
      height: 88px

    .cardLabel
      font-weight: 500
      letter-spacing: 2px
      color: #040038
      text-transform: uppercase

      @include breakpoint('xxl')
        font-size: 12px
        letter-spacing: 1px

      @include breakpoint('xl')
        font-weight: 400
        
      @include breakpoint('lg')
        line-height: 1

    .cardValue
      font-size: 36px
      font-weight: 600
      color: #040038

      @include breakpoint('xxl')
        font-size: 20px

      @include breakpoint('xl')
        font-weight: 500

  .imageContainer
    position: relative
    height: min-content

    &::after
      content: ''
      position: absolute
      top: calc(-25% - 4px)
      left: calc(-25% - 6px)
      height: 72px
      width: 72px
      border-radius: 50%
      transition: all 0.3s

      @include breakpoint('xxl')
        height: 52px
        width: 52px

    > svg
      position: relative
      width: 40px
      height: 40px
      min-width: 40px
      min-height: 40px
      max-width: 40px
      max-height: 40px
      z-index: 1

      @include breakpoint('xxl')
        width: 28px
        height: 28px
        min-width: 28px
        min-height: 28px
        max-width: 28px
        max-height: 28px
