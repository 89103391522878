@import "../../../../sources/mixins"

.buyerCardWrapper
  display: flex
  justify-content: space-between
  gap: 20px
  padding: 1rem 1.5rem
  border: 1px solid #e5e5e5
  border-radius: 7px
  transition: all 0.3s

  @include breakpoint('xxl')
    padding: 0.75rem 1rem
    gap: 8px

  @include hover()
    transform: scale(1.05)
    cursor: pointer

  .buyerInfoWrapper
    display: flex
    flex-direction: column
    gap: 4px

    .emailWrapper
      max-width: 200px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

      @include breakpoint('xxl')
        max-width: 120px

      @include breakpoint('xl')
        max-width: 100%

      @include breakpoint('lg')
        max-width: 160px

      @include breakpoint('md')
        max-width: 100%

      > span
        font-weight: 500
        color: #b0b0b0

        @include breakpoint('xxl')
          font-size: 10px
          font-weight: 400

    > span
      &:nth-child(1)
        font-size: 16px
        font-weight: 500
        color: #000130
        text-transform: capitalize

        @include breakpoint('xxl')
          font-weight: 400
          font-size: 14px

    .dealsCountWrapper
      display: flex
      align-items: center
      gap: 4px

      > span
        font-weight: 500
        color: #00014f

      > svg
        height: 16px
        width: 16px
        fill: #00014f

  .rightSideWrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
