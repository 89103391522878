@import "../../../globals"
.debit-agreement
  .title
    color: $primary!important
  .sub-title
    width: 100%
    text-align: center
    margin-bottom: 2em
    span
      display: block
      color: black
  .bold
    font-weight: bold
  .underline
    text-decoration: underline
  .block
    margin-bottom: 1em
    span
      display: block
  p
    text-align: justify
    color: black!important
  .ui.celled.table
    margin-bottom: 1em
  .ui.unstackable.table
    margin-bottom: 0
  .calendar
    .table
      width: 100%!important
      min-width: auto!important
      thead, tbody, tr
        width: 100%!important
        min-width: auto!important
        th
          i
            display: none
          pointer-events: none!important
        td, th
          width: auto!important
          padding: 5px 1px!important
          outline: none!important
        td.active
          background: white!important
        td:hover
          outline: none!important
          cursor: default!important
  .blue
    color: $primary!important
  .green
    color: $positive!important
  .fieldset
    border: 1px solid $primary
  .divider
    border-bottom: 1px solid $primary!important
  .footer
    span
      display: block
      color: $primary!important