@import  "../../utilities/globals"

.profileSelector
  .sliderWrapper
    padding: 2rem
    max-width: 380px
    border-radius: 5px
    border: 1px solid rgba(34,36,38,.15)

    .sliderLabels
      display: flex
      justify-content: space-between
      align-items: center
      gap: 1rem
      margin-bottom: 1rem

      > span
        font-size: 16px
        font-weight: 600
        text-transform: uppercase

    .trackWrapper
      padding: 0 1rem
      margin-bottom: 1rem

      .rc-slider-dot
        background: $primary
        border-color: $primary
        bottom: -4px
        width: 10px
        height: 10px

      .rc-slider-rail
        background: $primary!important
        height: 6px

      .rc-slider-handle
        background: $secondary !important
        border-color: $secondary !important
        width: 16px
        height: 16px

      .rc-slider-dot-active
        background: white
        border-color: $secondary !important

      .rc-slider-track
        background: $secondary !important
        height: 6px
