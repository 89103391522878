@import "../globals"
@import "../../../sources/mixins"

.credit-solution
  .table-wrapper
    height: 45vh
    overflow-y: auto
    overflow-x: auto
    width: 100%

    .customTable
      border-spacing: 1px 10px !important

  .ui.table
    background: white !important

    thead
      tr:first-child > th
        position: sticky !important
        top: -1px
        z-index: 2

      th
        background: white !important

  .search-form
    margin-top: 1rem

    div.customInputWrapper,
    div.customDropdownWrapper,
    div.dateInputWrapper
      margin-bottom: 0

    .datesWrapper
      div.ui.grid .column
        padding-inline: 0.25rem !important
        width: 50% !important
          
        @include breakpoint('sm')
          width: 100% !important

          &:first-child
            margin-bottom: 0.5rem !important

    .ui.selection.dropdown
      min-width: 8em !important

    @include breakpoint('md')
      .fields
        gap: 12px

    .btn-filter
      display: flex
      align-items: end
      justify-content: center

      button
        min-width: 50%

  button.creditButton, button.creditButton:active, button.creditButton:focus
    background-color: $primary
    color: white
    border: 1px solid $primary
    border-radius: 20px
    margin: 5px 0
    width: 100%
    &:hover
      background-color: white
      color: $primary

.credit-report
  .score
    color: $primary!important
  .trades
    strong
      display: block
  .grid, .segment
    background: white!important

@media only screen and (max-width: 768px)
  .credit-solution
    .table-wrapper
      height: max-content
  .credit-report
    .trades.ui.horizontal.segments
      display: block
      .ui.segment
        border-bottom: 1px solid $gray
