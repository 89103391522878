@import "../../../../globals"
@import "../../../../../../sources/mixins"

.listItem
  display: flex
  justify-content: space-between
  align-items: center

  > span
    font-size: 14px
    font-weight: 500
    color: $textColor

    @include breakpoint('xl')
      font-size: 12px
      font-weight: 400

  .iconWrapper
    width: 20px
    height: 20px

    @include breakpoint('lg')
      width: 16px
      height: 16px

    > svg
      width: 20px
      height: 20px

      @include breakpoint('lg')
        width: 16px
        height: 16px

.dangerColor
  stroke: #cd0000
