@import "../globals"
@import "../../../sources/mixins"

.container-slider
    div.segmentGrapper
        height: 100%
        -webkit-box-shadow: -2px -1px 21px -14px rgba(0,0,0,0.59)
        -moz-box-shadow: -2px -1px 21px -14px rgba(0,0,0,0.59)
        box-shadow: -2px -1px 21px -14px rgba(0,0,0,0.59)
        background: transparent

        .header-slider
            display: flex
            justify-content: space-between
            align-items: center
            min-height: 32px

            h3
                display: inline-block
                margin-bottom: 0
                color: $tertiary!important

                i
                    font-size: 1.25rem
                    vertical-align: top

            .customInputWrapper
                padding: 5px
                width: 35%
                color: $tertiary
                font-weight: bold

                .ui.input
                    display: flex !important

            .customInputWrapper > input
                color: $tertiary!important
                font-weight: bold!important
                font-size: 20px

            .header-inside
                display: flex
                justify-content: space-between
                align-items: center

        .sliderGrapper
            width: 100%
            height: 50px
            margin: 10px 0
            padding: 0 30px
            .rc-slider-dot
                background: $primary
                border-color: $primary
                bottom: -4px
                width: 10px
                height: 10px
            .rc-slider-rail
                background: $primary!important
                height: 6px
            .rc-slider-handle
                background: $secondary !important
                border-color: $secondary !important
                width: 16px
                height: 16px
            .rc-slider-dot-active
                background: white
                border-color: $secondary !important
            .rc-slider-track
                background: $secondary !important
                height: 6px
            .rc-slider-disabled
                background: none
                span
                    cursor: auto!important
                .rc-slider-handle, span.rc-slider-dot
                    cursor: auto!important

            .rc-slider-mark-text
                white-space: nowrap

        .sliderScore
            .rc-slider-dot
                background: white
                border-color: $secondary
                bottom: -5px
                width: 14px
                height: 14px
                margin-left: -8px
            .rc-slider-rail
                background: $secondary!important
                height: 4px
            .rc-slider-handle
                background: $primary !important
                border-color: $primary !important
                width: 18px
                height: 18px
                bottom: -2px
            .rc-slider-dot-active
                background: white
                border-color: $secondary !important
            .rc-slider-track
                background: $secondary !important
                height: 4px

    .reset-wrapper
        text-align: center
        .a2uWeekly
            position: absolute
            left: 1rem
        button.btn-return, button.btn-return:active, button.btn-return:focus
            background: $secondary
            border-radius: 20px
            color: white
            font-weight: normal
            border: 1px solid $secondary
            &:hover
                background: white
                color: $secondary

.editable
    @include breakpoint('xl')
        div.column
            padding: 0.5rem ! important

    div.segmentGrapper
        .header-slider
            min-height: 64px

            h3
                white-space: nowrap

                @include breakpoint('xl')
                    font-size: 16px

            .customInputWrapper
                width: 30%

                @include breakpoint('xl')
                    font-size: 16px
                    min-width: 120px

                    input
                        padding: 8px 16px

                @include breakpoint('md')
                    min-width: 140px

.wdc-tooltip
    position: absolute
    top: 100
    left: 50%
    transform: translate(-50%, -100%)
    z-index: 9999
    font-size: 10px
    line-height: 1.5
    opacity: 0.9
    user-select: none
    visibility: hidden
    opacity: 0
    transition: all ease-in-out 150ms
    &.active
        visibility: visible
        opacity: 1
        top: -5px
    span
        display: block
        text-align: center
        color: #fff
        text-decoration: none
        background-color: #373737
        border-radius: 5px
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.17)
        position: relative
        font-weight: bold
        user-select: none
        padding: 5px 10px
        &:after
            content: ''
            position: absolute
            width: 0
            height: 0
            border-color: transparent
            border-style: solid
            border-width: 5px 5px 0
            border-top-color: #373737
            left: 50%
            bottom: 0
            transform: translate(-50%, 100%)

@media only screen and (min-width: 992px)
    div.container-slider
        div.segmentGrapper
            padding: 1em 1em
            .sliderGrapper
                padding: 0 30px
                .rc-slider-mark-text
                    font-size: 12px

@media only screen and (max-width: 991px) and (min-width: 768px)
    div.container-slider
        div.colum-slider
            padding: 1em 1em!important
        div.segmentGrapper
            padding: 1em 1em
            .sliderGrapper
                padding: 0 30px
                .rc-slider-mark-text
                    font-size: 12px

@media only screen and (max-width: 768px)
    .container-slider
        // height: 100%
        // display: flex
        // align-items: center
        div.colum-slider
            padding: 5px!important
        div.segmentGrapper
            padding: 0.5rem

            .headers-inside
                display: block
            .header-slider
                input
                    padding: 5px
                .header-inside
                    display: flex
                    justify-content: space-between
                    align-items: center
                    width: 100%
            .sliderGrapper
                padding: 0 20px
                .rc-slider-mark-text
                    font-size: 9px
        .reset-wrapper
            text-align: center
            .a2uWeekly
                position: static
                display: block
                left: 1rem
