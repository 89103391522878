@import "../../utilities/globals.sass"
@import "../../../sources/mixins"

.yearInputWrapper
  position: relative
  margin-bottom: 1rem
  min-height: 40px
  border: 1px solid #d7d7d7
  border-radius: 9px
  -webkit-box-shadow: 0 0 0 2px transparent
  -moz-box-shadow: 0 0 0 2px transparent
  box-shadow: 0 0 0 2px transparent
  transition: all 0.3s

  &:not(.danger)
    @include hover()
      border-color: $primary

  &:not(.danger):focus-within
    -webkit-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    -moz-box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    box-shadow: 0 0 0 2px rgba(65,174,255,0.3)
    border-color: $primary

  input
    border: none !important
    border-radius: 9px !important
