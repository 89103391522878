@import  "../globals"
.grappper-notfound
    height: 100vh
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    h2
        color: $tertiary
    h3
        color: $primary
        cursor: pointer